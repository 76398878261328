import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        textAlign: 'left',
        margin: 'auto'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        //backgroundColor: red[500],
    },
    head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
    },
    media: {
        height: 140,
    },
    main: {
        height: '100%'
    }
}));

function Token(props) {
    const classes = useStyles();

    console.log(
        props
    )

    if (moment(props.date, 'MM/DD/YYYY').isAfter(moment())) {


        return (
            <Card className={classes.root} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={props.picture}
                        title={props.hospitalName}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {props.hospitalName}
                        </Typography>
                        <List>
                            <ListItem style={{ padding: '0' }}>

                                <ListItemAvatar>
                                    <Chip
                                        variant="outlined"
                                        size="medium"
                                        avatar={<Avatar>{String(props.number)}</Avatar>}
                                        label={
                                            ""
                                        }
                                        color="primary"

                                        deleteIcon={<DoneIcon />}
                                        style={{ marginRight: '1rem' }}
                                    />
                                </ListItemAvatar>
                                <Typography>
                                    {(moment(props.date).format("dddd, MMM Do @")) + props.time}
                                </Typography>
                                <ListItemText primary={props.slotType}
                                // secondary={"Available:" + (parseInt(props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotSize - props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotsAvailable))} 
                                />
                                <ListItemSecondaryAction>
                                    {/* {props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotsAvailable < props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotSize
                                    ?
                                    (<Button onClick={() => { reserveSlot(props, "5AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                    :
                                    (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                                } */}
                                </ListItemSecondaryAction>
                            </ListItem></List>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {props.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="small" color="primary">
                        Share
        </Button>
                    <Button size="small" color="primary">
                        Get Directions
        </Button>
                </CardActions>
            </Card>
        )
    }
    else {
        return (
            <React.Fragment></React.Fragment>
        )
    }
}


function TokenDisplay(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    if (props.auth.UserAuthData !== undefined) {
        if (props.auth.UserAuthData.tokens !== undefined) {
            return (
                <div className="App">
                    <header className="App-header">
                        <Typography variant="h4" gutterBottom style={{ padding: '1rem', textAlign: 'left' }}>
                            Your Bookings for this week
                    </Typography>

                        <div style={{ marginBottom: '5rem' }}>
                            {props.auth.UserAuthData.tokens.map(({
                                id,
                                number,
                                visited,
                                type,
                                hospitalId,
                                name,
                                slotDate,
                                slotTime,
                                slotType,
                                displayPicture,
                                displayDescription,
                            }) => (
                                    <Token
                                        picture={displayPicture}
                                        description={displayDescription}
                                        time={slotTime}
                                        date={slotDate}
                                        slotType={slotType}
                                        hospitalName={name}
                                        type={type}
                                        number={number}
                                    ></Token>
                                ))}</div></header>
                </div>)
        }
    }

    else {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }
}

TokenDisplay.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})


export default connect(mapStateToProps, {

})(TokenDisplay)
