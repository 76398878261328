import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { blue } from '@material-ui/core/colors';

import {
    updateUser
} from './../../../actions/APIActions';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const ColoredRadio = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export const ProfileForm = (props) => {
    const classes = useStyles();

    const [gender, setGender] = React.useState(props.auth.UserAuthData.gender === undefined ? "MALE" : props.auth.UserAuthData.gender);
    const [phoneNo, setPhoneNo] = React.useState(props.auth.UserAuthData.phone)
    const [formError, setFormError] = React.useState(false);

    const validatePhoneNo = (phoneNo) => {
        const re = /^[0-9]{10}$/g;
        return re.test(phoneNo);
    }

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        console.log(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhoneNo(event.target.value);
        console.log(event.target.value);

        if (validatePhoneNo(event.target.value)){
            setFormError(false)
        }

        else {
            setFormError(true)
        }
    };

    const saveDetails = () => {
        if (validatePhoneNo(phoneNo)){
            props.updateUser(
                props.auth.UserAuthData._id,
                phoneNo,
                gender
            )
        }
    }

    return (
        <React.Fragment>
            <Typography className={classes.text} variant="h4">
                Your Details
            </Typography>
            <br></br>
            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    variant="outlined" InputLabelProps={{
                        shrink: true,
                    }}
                    labelId="demo-simple-select-outlined-label1"
                    id="NameDep"
                    defaultValue={phoneNo}
                    onChange={handlePhoneChange}
                    label="Phone No."
                >
                </TextField>
                <FormHelperText id="component-error-text">{formError ? "Incorrect Number Format" : ""}</FormHelperText>
            </FormControl>
            <br></br>
            <br></br>
            <FormControl component="fieldset" style={{ marginRight: "4rem" }}>
                <FormLabel component="legend" style={{ textAlign: "left", }}>Gender</FormLabel>
                <RadioGroup aria-label="slot for" name="slotfor" value={gender} onChange={handleGenderChange}>
                    <FormControlLabel value="MALE" control={<ColoredRadio />} label="Male" />
                    <FormControlLabel value="FEMALE" control={<ColoredRadio />} label="Female" />
                    <FormControlLabel value="OTHER" control={<ColoredRadio />} label="Other" />
                </RadioGroup>
            </FormControl><br></br>
            <br></br>
            <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={saveDetails}
            >
                Save
      </Button>

        </React.Fragment>
    )
}

ProfileForm.propTypes = {
    auth: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    api: state.api,
})

export default connect(mapStateToProps, {
    updateUser,
})(ProfileForm)
