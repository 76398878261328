import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'react-redux-firebase';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

var moment = require('moment');

const useStyles = makeStyles(theme => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    root: {
    },
}));

function TicketDisplay(props) {
    const classes = useStyles();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    if (props.auth.UserAuthData !== undefined && props.auth.UserAuthData.activity !== undefined && props.auth.UserAuthData.activity.slot !== "") { // && (moment(props.auth.UserAuthData.activity.slot, "HHa").add(1, 'hours')).isSameOrAfter(moment())) {
        return (
            <React.Fragment>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        {/* <IconButton edge="start" color="inherit" aria-label="open drawer">
                            <MenuIcon />
                        </IconButton> */}
                        <div className={classes.fabButton}>
                            <Fab variant="extended" color="secondary" aria-label="up" onClick={toggleDrawer("bottom", true)}>
                                <TurnedInIcon className={classes.extendedIcon} />View Bookings
                        </Fab>
                        </div>
                        <SwipeableDrawer
                            anchor={"bottom"}
                            open={state["bottom"]}
                            onClose={toggleDrawer("bottom", false)}
                            onOpen={toggleDrawer("bottom", true)}
                        >
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt="Store Image"
                                        height="425"
                                        image="https://images.pexels.com/photos/1005638/pexels-photo-1005638.jpeg?cs=srgb&dl=grocery-cart-with-item-1005638.jpg&fm=jpg"
                                        title="Store Image"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h1">
                                            {props.auth.UserAuthData.activity.storeName}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
          {<b>{props.auth.UserAuthData.activity.slotNo + " | " + (moment(props.auth.UserAuthData.activity.slot, "HHa").format("dddd, MMM Do @ ha"))}</b>}
          </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                        {props.auth.UserAuthData.activity.storeLocation} @ <b>{props.auth.UserAuthData.activity.slot + " Today"}</b>
          </Typography>
          <br></br>
          <Typography variant="body2" color="textSecondary" component="p">
                                            Remember to carry your Mask and maintain distance when in line :D
          </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary">
                                        Share
        </Button>
                                    <Button size="small" color="primary">
                                        Get Route
        </Button>
                                </CardActions>
                            </Card>
                        </SwipeableDrawer>
                        <div className={classes.grow} />
                        {/* <IconButton edge="end" color="inherit" onClick={() => {console.log("Clicked")}}>
                            <Typography  variant="body2" color="textPrimary" component="p" style={{marginRight:'0.3rem'}}>Sign out </Typography><ExitToAppIcon />
                        </IconButton> */}
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        )
    }

    else {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }

}

TicketDisplay.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    auth: state.auth,
});

export default connect(mapStateToProps, {

})(TicketDisplay);