import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainChooser from '../../components/medical/customer/MainChooser';
import SecondaryChooser from '../../components/medical/customer/SecondaryChooser';
import TicketDisplay from '../../components/customer/TicketDisplay';
import PrimaryAppBar from '../../components/PrimaryAppBar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from './../../logo.svg';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {
    setError,
} from './../../actions/ErrorActions';

const useStyles = makeStyles({
    root: {
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
});

function MedicalCustomerPage(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.setError(false)
    };

    return (
        <div className="App">
            <PrimaryAppBar></PrimaryAppBar>
            <header className="App-header">
                <Typography className={classes.text} variant="h1">
                    Braggi
              </Typography>
                <Typography className={classes.text} variant="h6">
                    Shop Safely during Lockdowns
              </Typography>
                <img src={logo} className="App-logo" alt="logo" />
                <MainChooser></MainChooser>
            </header>
            {/*<TabPanel></TabPanel>*/}
            <SecondaryChooser></SecondaryChooser>
            <Dialog
                    open={props.error.error}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{props.error.errorMessage.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.error.errorMessage.body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Dismiss
          </Button>
                    </DialogActions>
                </Dialog>
            {/* <TicketDisplay></TicketDisplay> */}
        </div>
    )
}
MedicalCustomerPage.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    setError: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error,
});

export default connect(mapStateToProps, {
    setError,
})(MedicalCustomerPage);
