import {
    GETSTATELIST,
    SETSTATE,
    GETCITYLIST,
    SETCITY,
    GETAREALIST,
    SETAREA,
    GETLOCATIONLIST,
    SETLOCATION,
    GETSTORELIST,
    SETSTORE,
    GETSTOREDETAILS,
    GETSTOREDETAILSBYID,
    SETTIMINGS,
    SAVEAUTHINFO, SETUNBANTIME, SETRESERVELOCK,
    //MED//
    GETSTATELIST_MED,
    SETSTATE_MED,
    GETCITYLIST_MED,
    SETCITY_MED,
    GETAREALIST_MED,
    SETAREA_MED,
    GETLOCATIONLIST_MED,
    SETLOCATION_MED,
    GETHOSPITALLIST,
    SETHOSPITAL,
    GETHOSPITALUNITSLIST,
    SETHOSPITALUNIT,
    GETHOSPITALDETAILS,
    GETHOSPITALDETAILSBYID,
    GETTESTTYPE,
    SETTESTTYPE,
    GETTESTS,
    SETTEST,
    SETTICKET_MED,
    SETTIMINGS_MED,
    SETFAVORITES,
    SETTIME_MED,
    SETDATE_MED,
    SETDEPENDENCY_MED,
    SETGENDER_MED,
    SETNAME_MED,
    SETPHNO_MED,
    //MED ADMIN//
    SETTIMINGS_ADMIN,
    SETWORKERS,
    SETADDERCONTENT,
    //ERROR//
    SETERROR,
    SETERRORMESSAGE,
}
    from './../actions/types';
// import {
//     socket
// } from '../sockets/index';
import axios from 'axios';
import store from './../store';
export const getStateList = () => dispatch => {
    //socket.emit("getStateList", {get: true});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/getStateList", { get: true }).then(
        res => {
            dispatch({
                type: GETSTATELIST,
                payload: res.data.stateList
            })
        }
    );
}

export const setStateName = (stateName) => dispatch => {
    dispatch({
        type: SETSTATE,
        payload: {
            selectedState: stateName
        }
    })
}

export const getCityList = (stateName) => dispatch => {
    //socket.emit("getCityList", {stateName: stateName});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/getCityList", { stateName: stateName }).then(
        res => {
            dispatch({
                type: GETCITYLIST,
                payload: res.data.cityList
            })
        }
    )
}

export const setCityName = (cityName) => dispatch => {
    dispatch({
        type: SETCITY,
        payload: {
            selectedCity: cityName
        }
    })
}

export const getAreaList = (stateName, cityName) => dispatch => {
    //socket.emit("getAreaList", {stateName: stateName, cityName: cityName});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/getAreaList", { stateName: stateName, cityName: cityName }).then(
        res => {
            dispatch({
                type: GETAREALIST,
                payload: res.data.areaList
            })
        }
    )
}

export const setAreaName = (areaName) => dispatch => {
    dispatch({
        type: SETAREA,
        payload: {
            selectedArea: areaName
        }
    })
}

export const getLocationList = (stateName, cityName, areaName) => dispatch => {
    //socket.emit("getLocationList", {stateName: stateName, cityName: cityName, areaName: areaName});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/getLocationList", { stateName: stateName, cityName: cityName, areaName: areaName }).then(
        res => {
            dispatch({
                type: GETLOCATIONLIST,
                payload: res.data.locationList
            })
        }
    )
}

export const setLocationName = (locationName) => dispatch => {
    dispatch({
        type: SETLOCATION,
        payload: {
            selectedLocation: locationName
        }
    })
}

export const getStoreList = (stateName, cityName, areaName, locationName) => dispatch => {
    //socket.emit("getStoreList", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/getStoreList", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName }).then(
        res => {
            dispatch({
                type: GETSTORELIST,
                payload: res.data.storeList
            })
        }
    )
}

export const setStoreName = (storeName) => dispatch => {
    dispatch({
        type: SETSTORE,
        payload: {
            selectedStore: storeName
        }
    })
}

export const getStoreDetails = (stateName, cityName, areaName, locationName, storeName) => dispatch => {
    //socket.emit("getStoreDetails", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/getStoreDetails", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName }).then(
        res => {
            dispatch({
                type: GETSTOREDETAILS,
                payload: res.data.storedetails[0]
            })
        }
    )
}
export const getStoreDetailsList = (stateName, cityName, areaName, locationName) => dispatch => {
    //socket.emit("getStoreDetailsList", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/getStoreDetailsList", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName }).then(
        res => {
            dispatch({
                type: GETSTORELIST,
                payload: res.data.storeList
            })
        }
    )
}

export const getStoreDetailsbyId = (_id) => dispatch => {
    //socket.emit("getStoreDetailsbyid", {_id: _id});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/getStoreDetailsbyid", { _id: _id }).then(
        res => {
            dispatch({
                type: GETSTOREDETAILS,
                payload: res.data.storedetails[0]
            })
        }
    )
}
export const updateWorkers = (_id, workers) => {
    //socket.emit("addWorkers", {_id: _id, workers: workers});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/addWorkers", { _id: _id, workers: workers }).then(
        res => {
            console.log("Success")
        }
    )
}
export const updateTimings = (_id, timings) => dispatch => {
    //socket.emit("addWorkers", {_id: _id, workers: workers});
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/updateStoreTimings", { _id: _id, storeTimings: timings }).then(
        res => {
            console.log("res: ", res)
            dispatch({
                type: SETTIMINGS,
                payload: timings
            })
            console.log("Success")
        }
    )
}
export const opencloseShop = (_id, openCheck) => {
    //socket.emit("opencloseShop", {_id: _id, openCheck: openCheck})
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/opencloseShop", { _id: _id, openCheck: openCheck }).then(
        res => {
            console.log("Success")
        }
    )
}
export const updateSlotSize = (_id, slotSize) => {
    //socket.emit("opencloseShop", {_id: _id, openCheck: openCheck})
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/updateSlotSize", { _id: _id, slotSize: slotSize }).then(
        res => {
            console.log("Success")
        }
    )
}
export const updateVisited = (_id, number, visited, time) => dispatch => {
    //socket.emit("updateVisited", {_id: _id, number: number, visited: visited, time: time})
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/updateVisited", { _id: _id, number: number, visited: visited, time: time }).then(
        res => {
            dispatch({
                type: GETSTOREDETAILS,
                payload: res.data.storedetails
            })
        }
    )
}
export const api_reserveSlot = (stateName, cityName, areaName, locationName, storeName, time, _id) => dispatch => {
    console.log("Reserving slot for :", stateName, cityName, areaName, locationName, storeName, time, _id)
    //socket.emit("reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id:_id})
    axios.post(process.env.REACT_APP_API_URL + "storeAPI/reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id: _id }).then(
        res => {
            if (res.data.updatedDetails === "NOP") {
                console.log("NOP")
            }
            else {
                dispatch({
                    type: GETSTOREDETAILS,
                    payload: res.data.updatedDetails
                })
                dispatch({
                    type: SAVEAUTHINFO,
                    payload: res.data.userAuthData
                })
                if (res.data.userAuthData.unbanTime) {
                    dispatch({
                        type: SETUNBANTIME,
                        payload: res.data.userAuthData.unbanTime
                    })
                    dispatch({
                        type: SETRESERVELOCK,
                        payload: res.data.userAuthData.banned
                    })
                }
            }
        }
    )
}

export const setTimings = (timings) => dispatch => {

    dispatch({
        type: SETTIMINGS,
        payload: timings
    })
}

////=============== MED ===============//////

export const getStateList_MED = () => dispatch => {
    //socket.emit("getStateList", {get: true});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getStateList", { get: true }).then(
        res => {
            dispatch({
                type: GETSTATELIST_MED,
                payload: res.data.stateList
            })
        }
    );
}

export const setStateName_MED = (stateName) => dispatch => {
    dispatch({
        type: SETSTATE_MED,
        payload: {
            selectedState: stateName
        }
    })
}

export const getCityList_MED = (stateName) => dispatch => {
    //socket.emit("getCityList", {stateName: stateName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getCityList", { stateName: stateName }).then(
        res => {
            dispatch({
                type: GETCITYLIST_MED,
                payload: res.data.cityList
            })
        }
    )
}

export const setCityName_MED = (cityName) => dispatch => {
    dispatch({
        type: SETCITY_MED,
        payload: {
            selectedCity: cityName
        }
    })
}

export const getAreaList_MED = (stateName, cityName) => dispatch => {
    //socket.emit("getAreaList", {stateName: stateName, cityName: cityName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getAreaList", { stateName: stateName, cityName: cityName }).then(
        res => {
            dispatch({
                type: GETAREALIST_MED,
                payload: res.data.areaList
            })
        }
    )
}

export const setAreaName_MED = (areaName) => dispatch => {
    dispatch({
        type: SETAREA_MED,
        payload: {
            selectedArea: areaName
        }
    })
}

export const getLocationList_MED = (stateName, cityName, areaName) => dispatch => {
    //socket.emit("getLocationList", {stateName: stateName, cityName: cityName, areaName: areaName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getLocationList", { stateName: stateName, cityName: cityName, areaName: areaName }).then(
        res => {
            dispatch({
                type: GETLOCATIONLIST_MED,
                payload: res.data.locationList
            })
        }
    )
}

export const setLocationName_MED = (locationName) => dispatch => {
    dispatch({
        type: SETLOCATION_MED,
        payload: {
            selectedLocation: locationName
        }
    })
}

export const getHospitalList_MED = (stateName, cityName, areaName, locationName) => dispatch => {
    //socket.emit("getStoreList", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getHospitalList", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName }).then(
        res => {
            dispatch({
                type: GETHOSPITALLIST,
                payload: res.data.storeList
            })
        }
    )
}

export const setHospitalName_MED = (hospitalName) => dispatch => {
    dispatch({
        type: SETHOSPITAL,
        payload: {
            selectedHospital: hospitalName
        }
    })
}

export const getTestsList_MED = (stateName, cityName, areaName, locationName, storeName) => dispatch => {
    //socket.emit("getStoreDetails", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getTestsList", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, hospitalName: storeName }).then(
        res => {
            dispatch({
                type: GETTESTTYPE,
                payload: res.data.storeList
            })
        }
    )
}

export const setTestCategoryName_MED = (testCategoryName) => dispatch => {
    dispatch({
        type: SETTESTTYPE,
        payload: {
            selectedTestType: testCategoryName
        }
    })
}

export const getTests_MED = (stateName, cityName, areaName, locationName, storeName, testCategoryName) => dispatch => {
    //socket.emit("getStoreDetails", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getTests", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, hospitalName: storeName, testCategory: testCategoryName }).then(
        res => {
            dispatch({
                type: GETTESTS,
                payload: res.data.storeList
            })
        }
    )
}

export const setTestName_MED = (testName) => dispatch => {
    dispatch({
        type: SETTEST,
        payload: {
            selectedTest: testName
        }
    })
}


export const getHospitalUnits_MED = (stateName, cityName, areaName, locationName, storeName) => dispatch => {
    //socket.emit("getStoreDetails", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getHospitalUnitList", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, hospitalName: storeName }).then(
        res => {
            dispatch({
                type: GETHOSPITALUNITSLIST,
                payload: res.data.storedetails
            })
        }
    )
}

export const setStoreName_MED = (storeName) => dispatch => {
    dispatch({
        type: SETSTORE,
        payload: {
            selectedStore: storeName
        }
    })
}

export const setDependency_MED = (dependency) => dispatch => {
    dispatch({
        type: SETDEPENDENCY_MED,
        payload: dependency
    })
}

export const setGender_MED = (gender) => dispatch => {
    dispatch({
        type: SETGENDER_MED,
        payload: gender
    })
}

export const setName_MED = (name) => dispatch => {
    dispatch({
        type: SETNAME_MED,
        payload: name
    })
}

export const setPhNo_MED = (number) => dispatch => {
    dispatch({
        type: SETPHNO_MED,
        payload: number
    })
}

export const setTime_MED = (time) => dispatch => {
    dispatch({
        type: SETTIME_MED,
        payload: {
            timeMED: time
        }
    })
}

export const setDate_MED = (date) => dispatch => {
    dispatch({
        type: SETDATE_MED,
        payload: {
            dateMED: date
        }
    })
}

export const getSlots_MED = (stateName, cityName, areaName, locationName, hospitalName, testCategoryName, testName, time, date) => dispatch => {
    //socket.emit("getStoreDetails", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/getSlots", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, hospitalName: hospitalName, testCategory: testCategoryName, testName: testName, slotTime: time, slotDate: date }).then(
        res => {
            dispatch({
                type: GETHOSPITALDETAILS,
                payload: {
                    selectedHospitalDetails: res.data.storedetails[0],
                    success: res.data.success
                }
            })
        }
    )
}

export const addFavorites_MED = (_id, stateName, cityName, areaName, locationName, storeName) => dispatch => {
    //socket.emit("getStoreDetails", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/addFavorite",
        {
            _id: _id,
            stateName: stateName,
            cityName: cityName,
            areaName: areaName,
            locationName: locationName,
            hospitalName: storeName
        }).then(
            res => {
                if (res.data.userAuthData !== null) {
                    dispatch({
                        type: SETFAVORITES,
                        payload: res.data.userAuthData.bookmarks
                    })
                }
            }
        )
}

export const api_reserveSlot_MED = (_id, displayName, phoneNo, stateName, cityName, areaName, locationName, hospitalName, hospital_id, testCategoryName, testName, time, date, dependency, gender, name) => dispatch => {
    console.log("Reserving slot for :", stateName, cityName, areaName, locationName, hospitalName, hospital_id, time, _id)
    //socket.emit("reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id:_id})
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/reserveSlot", {
        stateName: stateName,
        cityName: cityName,
        areaName: areaName,
        locationName: locationName,
        hospitalName: hospitalName,
        testCategory: testCategoryName,
        testName: testName,
        slotTime: time,
        slotDate: date,
        _id: _id,
        phoneNo: phoneNo,
        id: hospital_id,
        dependency: dependency,
        gender,
        userName: name,
    }).then(
        res => {
            if (res.data.success === false) {
                console.log("NOP")
            }
            else {
                dispatch({
                    type: GETHOSPITALDETAILS,
                    payload: res.data.storedetails
                })
                dispatch({
                    type: SAVEAUTHINFO,
                    payload: res.data.userAuthData
                })
                // if (res.data.userAuthData.unbanTime) {
                //     dispatch({
                //         type: SETUNBANTIME,
                //         payload: res.data.userAuthData.unbanTime
                //     })
                //     dispatch({
                //         type: SETRESERVELOCK,
                //         payload: res.data.userAuthData.banned
                //     })
                // }
            }
        }
    )
}

export const updateUser = (_id, phoneNo, gender) => dispatch => {
    //socket.emit("getStoreDetails", {stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName});
    axios.post(process.env.REACT_APP_API_URL + "medicalAPI/update-user", { _id: _id, phoneNo: phoneNo, gender: gender }).then(
        res => {
            if (res.data.userAuthData !== null) {
                dispatch({
                    type: SAVEAUTHINFO,
                    payload: res.data.userAuthData
                })
                dispatch({
                    type: SETERROR,
                    payload: true
                })
                dispatch({
                    type: SETERRORMESSAGE,
                    payload: {
                        title: "Saved Successfully",
                        body: "We have updated your Details! Thank you!",
                    }
                })
            }
        }
    )
}

export const getHospitalInfo = (hospitalId) => dispatch => {
    console.log("Getting Hospital details of :", hospitalId)
    //socket.emit("reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id:_id})
    axios.post(process.env.REACT_APP_API_URL + "medical-admin-api/hospital-info", { hospitalId: hospitalId }).then(
        res => {
            if (res.data.success === false) {
                console.log("NOP")
            }
            else {
                dispatch({
                    type: GETHOSPITALDETAILS,
                    payload: res.data.hospitaldetails
                })
            }
        }
    )
}

export const getHospitalUnits_Admin = (hospitalId) => dispatch => {
    console.log("Getting Hospital Units of :", hospitalId)
    //socket.emit("reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id:_id})
    axios.post(process.env.REACT_APP_API_URL + "medical-admin-api/admin-units", { hospitalId: hospitalId }).then(
        res => {
            if (res.data.success === false) {
                console.log("NOP")
            }
            else {
                dispatch({
                    type: GETHOSPITALUNITSLIST,
                    payload: res.data.stateList
                })
            }
        }
    )
}

export const setHospitalUnit = (hospitalUnit) => dispatch => {
    dispatch({
        type: SETHOSPITALUNIT,
        payload: hospitalUnit
    })
}

export const getSlotListofUnit = (hospitalId, hospitalName, hospitalUnitName, hospitalUnitCategoryName) => dispatch => {
    console.log("Getting Hospital Units of :", hospitalId)
    //socket.emit("reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id:_id})
    axios.post(process.env.REACT_APP_API_URL + "medical-admin-api/slot-list-of-unit", { hospitalId: hospitalId, hospitalName: hospitalName, hospitalUnitName: hospitalUnitName, hospitalUnitCategoryName: hospitalUnitCategoryName }).then(
        res => {
            if (res.data.success === false) {
                console.log("NOP")
            }
            else {
                dispatch({
                    type: SETHOSPITALUNIT,
                    payload: res.data.stateList
                })
            }
        }
    )
}

export const setTimings_Admin = (timings) => dispatch => {
    dispatch({
        type: SETTIMINGS_ADMIN,
        payload: timings
    })
}

export const setWorkers = (workers) => dispatch => {
    dispatch({
        type: SETWORKERS,
        payload: workers
    })
}

export const setAdderContent = (adderContent) => dispatch => {
    dispatch({
        type: SETADDERCONTENT,
        payload: adderContent
    })
}

export const editUnit = (hospitalId, stateName, cityName, areaName, locationName, hospitalName, hospitalUnitName, hospitalUnitCategoryName, {
    openCheck,
    slotSize,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
    displayPicture,
    displayDescription,
    address,
    workerIds,
}) => dispatch => {
    console.log("Editing Hospital Details of: ", hospitalId)
    //socket.emit("reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id:_id})
    axios.post(process.env.REACT_APP_API_URL + "medical-admin-api/edit-unit",
        {
            stateName: stateName,
            cityName: cityName,
            areaName: areaName,
            locationName: locationName,
            hospitalId: hospitalId,
            hospitalName: hospitalName,
            hospitalUnitName: hospitalUnitName,
            hospitalUnitCategoryName: hospitalUnitCategoryName,
            openCheck: openCheck,
            slotSize: slotSize,
            Monday: Monday,
            Tuesday: Tuesday,
            Wednesday: Wednesday,
            Thursday: Thursday,
            Friday: Friday,
            Saturday: Saturday,
            Sunday: Sunday,
            displayPicture: displayPicture,
            displayDescription: displayDescription,
            address: address,
            workerIds: workerIds,
        }).then(
            res => {
                if (res.data.success === false) {
                    console.log("NOP")
                    dispatch({
                        type: SETERROR,
                        payload: true
                    })
                    dispatch({
                        type: SETERRORMESSAGE,
                        payload: {
                            title: "Error",
                            body: "Failed to Save",
                        }
                    })
                }
                else {
                    // dispatch({
                    //     type: SETHOSPITALUNIT,
                    //     payload: res.data.stateList
                    // })
                    dispatch({
                        type: GETHOSPITALDETAILS,
                        payload: res.data.stateList
                    })
                    dispatch({
                        type: SETERROR,
                        payload: true
                    })
                    dispatch({
                        type: SETERRORMESSAGE,
                        payload: {
                            title: "Saved Successfully",
                            body: "Updated Hospital Details!",
                        }
                    })
                }
            }
        )
}

export const editHospital = (hospitalId, stateName, cityName, areaName, locationName, hospitalName, {
    openCheck,
    displayPicture,
    displayDescription,
    address,
    workerIds,
}) => dispatch => {
    console.log("Editing Hospital Details of: ", hospitalId)
    //socket.emit("reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id:_id})
    axios.post(process.env.REACT_APP_API_URL + "medical-admin-api/edit-hospital",
        {
            stateName: stateName,
            cityName: cityName,
            areaName: areaName,
            locationName: locationName,
            hospitalId: hospitalId,
            hospitalName: hospitalName,
            openCheck: openCheck,
            displayPicture: displayPicture,
            displayDescription: displayDescription,
            address: address,
            workerIds: workerIds,
        }).then(
            res => {
                if (res.data.success === false) {
                    console.log("NOP")
                    dispatch({
                        type: SETERROR,
                        payload: true
                    })
                    dispatch({
                        type: SETERRORMESSAGE,
                        payload: {
                            title: "Error",
                            body: "Failed to Save",
                        }
                    })
                }
                else {
                    dispatch({
                        type: GETHOSPITALDETAILS,
                        payload: res.data.stateList
                    })
                    dispatch({
                        type: SETERROR,
                        payload: true
                    })
                    dispatch({
                        type: SETERRORMESSAGE,
                        payload: {
                            title: "Saved Successfully",
                            body: "Updated Hospital Details!",
                        }
                    })
                }
            }
        )
}

export const addUnit = (hospitalId, stateName, cityName, areaName, locationName, hospitalName, hospitalUnitName, hospitalUnitCategoryName, {
    openCheck,
    displayPicture,
    displayDescription,
    address,
    workerIds,
    slotSize,
    timings,
}) => dispatch => {
    console.log("Editing Hospital Details of: ", hospitalId)
    //socket.emit("reserveSlot", { stateName: stateName, cityName: cityName, areaName: areaName, locationName: locationName, storeName: storeName, time: time, _id:_id})
    axios.post(process.env.REACT_APP_API_URL + "medical-admin-api/add-unit",
        {
            stateName: stateName,
            cityName: cityName,
            areaName: areaName,
            locationName: locationName,
            hospitalId: hospitalId,
            hospitalName: hospitalName,
            hospitalUnitCategoryName: hospitalUnitCategoryName,
            hospitalUnitName: hospitalUnitName,
            openCheck: openCheck,
            displayPicture: displayPicture,
            displayDescription: displayDescription,
            address: address,
            workerIds: workerIds,
            slotSize: slotSize,
            timings: timings,
        }).then(
            res => {
                if (res.data.success === false) {
                    console.log("NOP")
                    dispatch({
                        type: SETERROR,
                        payload: true
                    })
                    dispatch({
                        type: SETERRORMESSAGE,
                        payload: {
                            title: "Error",
                            body: "Failed to Save",
                        }
                    })
                }
                else {
                    dispatch({
                        type: SETERROR,
                        payload: true
                    })
                    dispatch({
                        type: SETERRORMESSAGE,
                        payload: {
                            title: "Saved Successfully",
                            body: "Added New Unit! Refresh to see changes",
                        }
                    })
                }
            }
        )
}

export const getHeaders = () => {
    return {
        headers: {
            //"Authorization" : `Bearer ${token}`
            "x-auth-token": store.getState().auth.UserAuthData.JWT,
            'Content-Type': 'application/json'
        }
    }
}