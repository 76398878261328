import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history' 
import { connectRouter } from 'connected-react-router'
import { routerReducer, routerMiddleware } from 'react-router-redux';

const initialState = {};
const middleware = [thunk]; //thunk is a middleware
const history = createBrowserHistory()

const composeEnhancers =  typeof window === 'object' &&  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

  const composingMiddlewareAndDevTools = 
    process.env.REACT_APP_API_URL === "http://localhost:5000/" 
    ? composeEnhancers(
      applyMiddleware(...middleware, routerMiddleware(history))
      // other store enhancers if any
    )
    : applyMiddleware(...middleware, routerMiddleware(history))
  
// const composingMiddlewareAndDevTools = composeEnhancers(
//   applyMiddleware(...middleware, routerMiddleware(history)),
//   // other store enhancers if any
// );

const store = createStore(rootReducer,
                          initialState,
						  composingMiddlewareAndDevTools);

//console.log(store.getState());

export default store;
