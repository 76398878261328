import {
    SAVEAUTHINFO,
    SETTICKET,
    SETRESERVELOCK,
    SETUNBANTIME,
}
from './../actions/types';

const initialState = {
    UserAuthData: {},
    reserveLock: false,
    unbanTime: "",
    ticket: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVEAUTHINFO:
            {
                return {
                    ...state,
                    UserAuthData: action.payload
                };
            }

        case SETTICKET:
            {
                return {
                    ...state,
                    ticket: action.payload
                };
            }

        case SETRESERVELOCK:
            {
                return {
                    ...state,
                    reserveLock: action.payload
                };
            }

        case SETUNBANTIME:
            {
                return {
                    ...state,
                    unbanTime: action.payload
                };
            }

        default:
            {
                return state
            }

    }
}