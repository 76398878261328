import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainChooser from '../components/customer/MainChooser';
import SlotsDisplay from '../components/customer/SlotsDisplay';
import TicketDisplay from '../components/customer/TicketDisplay';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from './../logo.svg';

import LoadingPage from './loadingPage';
import CustomerPage from './customer';
import OwnerPage from './owner';
import WorkerPage from './worker';
import CopPage from './cop';
import PageRouter from './ShoppingPageRouter';
import MedicalPageRouter from './MedicalPageRouter';
import TokenDisplay from '../components/TokenDisplay';
import ProfilePage from './ProfilePage';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';

import PersonIcon from '@material-ui/icons/Person';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Badge from '@material-ui/core/Badge';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {
    setError,
} from './../actions/ErrorActions';

const useStyles = makeStyles({
    root: {
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
});

function TabRouter(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [tokenCount, setTokenCount] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        props.setError(false)
    };

    const showValidTokens = (tokens) => {
        let count = 0;
        tokens.map(({ slotDate, slotTime }) => {
            // console.log("Token Date: ", slotDate, slotTime)
            // console.log("Date: ", moment(slotDate + " " +slotTime, 'MM/DD/YYYY HHa'))
            // console.log("Date: ", moment(slotDate, 'MM/DD/YYYY', false))
            if (moment(slotDate, 'MM/DD/YYYY').isAfter(moment())) {
                count++;
            }
        })

        return count;
    }

    console.log("Tokens: ", props.auth.UserAuthData)
    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <AppBar position="fixed" color="default" className={classes.appBar}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Shopping" icon={<ShoppingCartIcon />} {...a11yProps(0)} />
                        <Tab label="Medical" icon={<LocalHospitalIcon />} {...a11yProps(1)} />
                        <Tab label="Bookings" icon={
                            <Badge badgeContent={
                                props.auth.UserAuthData !== undefined ? props.auth.UserAuthData.tokens !== undefined ?
                                    //props.auth.UserAuthData.tokens.length
                                    showValidTokens(props.auth.UserAuthData.tokens)
                                    : 0 : 0
                            } color="error">
                                <BookmarkIcon />
                            </Badge>
                        } {...a11yProps(2)} />
                        <Tab label="Profile" icon={<PersonIcon />} {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <PageRouter></PageRouter>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MedicalPageRouter></MedicalPageRouter>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TokenDisplay></TokenDisplay>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <ProfilePage></ProfilePage>
                </TabPanel>

                <Dialog
                    open={props.error.error}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{props.error.errorMessage.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.error.errorMessage.body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Dismiss
          </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </React.Fragment>
    )

}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div>
            {value === index && <Box >{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


TabRouter.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    setError: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error,
});

export default connect(mapStateToProps, {
    setError,
})(TabRouter);
