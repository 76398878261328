import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Divider from '@material-ui/core/Divider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Collapse from '@material-ui/core/Collapse';

import AddIcon from '@material-ui/icons/Add';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import FaceIcon from '@material-ui/icons/Face';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import Typography from '@material-ui/core/Typography';
import { blue, green } from '@material-ui/core/colors';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme) => ({
    avatarSelf: {
        backgroundColor: green[100],
        color: green[600],
    },
    avatarDependent: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const SlotReservations = (props) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [openDetails, setOpenDetails] = React.useState(false);

    const handleClick = () => {
        setOpenDetails(!openDetails);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    console.log("Emails: ", props.emails)

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                View Reservations 📖
      </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Reservations 📖"}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
          </DialogContentText> */}
                    <List>
                        {props.emails.map(({ _id, number, visited, name, phoneNo, gender, dependency }) => (
                            <React.Fragment>
                                <ListItem button onClick={handleClick}
                                >
                                    <ListItemAvatar>
                                        <Avatar className={dependency ? classes.avatarSelf : classes.avatarDependent}>
                                            {number}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={name} secondary={"Details"} />
                                    {openDetails ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={openDetails} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={classes.nested}>
                                            <ListItemIcon>
                                                <AlternateEmailIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={_id} />
                                        </ListItem>
                                        <ListItem button className={classes.nested}>
                                            <ListItemIcon>
                                                <PhoneIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={phoneNo === null ? "No Registered Phone Number" : phoneNo} />
                                        </ListItem>
                                        <ListItem button className={classes.nested}>
                                            <ListItemIcon>
                                                <FaceIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={gender}/>
                                        </ListItem>
                                        <ListItem button className={classes.nested}>
                                            <ListItemIcon>
                                                <DoneAllIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={visited ? "Visited" : "Visit Pending"}/>
                                        </ListItem>
                                    </List>
                                </Collapse>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    )
}

SlotReservations.propTypes = {
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SlotReservations)
