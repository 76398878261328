import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainChooser from '../components/customer/MainChooser';
import SlotsDisplay from '../components/customer/SlotsDisplay';
import TicketDisplay from '../components/customer/TicketDisplay';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from './../logo.svg';

import LoadingPage from './loadingPage';
import CustomerPage from './customer';
import OwnerPage from './owner';
import WorkerPage from './worker';
import CopPage from './cop';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles({
    root: {
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
});

function PageRouter(props) {
    const classes = useStyles();


    if (props.auth.UserAuthData.isWorker === false && props.auth.UserAuthData.isOwner === false && props.auth.UserAuthData.isCop === false && props.auth.UserAuthData.isGovt === false) {
        return (
            <CustomerPage></CustomerPage>
        )
    }

    else if (props.auth.UserAuthData.isWorker === true){
        return (
            <React.Fragment>
                <WorkerPage></WorkerPage>
            </React.Fragment>
        )
    }

    else if (props.auth.UserAuthData.isOwner === true){
        return (
            <React.Fragment>
                <OwnerPage></OwnerPage>
            </React.Fragment>
        )
    }

    else if (props.auth.UserAuthData.isCop === true){
        return (
            <React.Fragment>
                <CopPage></CopPage>
            </React.Fragment>
        )
    }

    else if (props.auth.UserAuthData.isGovt === true){
        return (
            <React.Fragment>
                Render Authority
            </React.Fragment>
        )
    }

    else {
        return (
            <React.Fragment>
                <LoadingPage></LoadingPage>
            </React.Fragment>
        )
    }

}

PageRouter.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {

})(PageRouter);
