import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';

import InputAdornment from '@material-ui/core/InputAdornment';

import SlotDrawer from './slotDrawer';
import SlotReservations from './slotReservations';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 420,
        textAlign: 'left',
        margin: 'auto',
        marginBottom: "1rem",
        marginLeft: "1rem",
        marginRight: "1rem"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
    },
    textfield: {
        marginBottom: '1rem'
    },
}));

const SlotDisplay = (props) => {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const update = () => {

    }

    return (
        <React.Fragment >
            {/* <Typography className={classes.text} variant="h5" align="center" style={{ marginTop: "1rem", marginBottom: "1rem" }}>Your Appointment</Typography> */}

            <Card className={classes.root}
            // variant={"outlined"}
            >
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {props.hospitalName[0]}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={props.hospitalName}
                    subheader={props.slot.slotDate + " @ " + props.slot.slotTime}
                />
                <CardMedia
                    className={classes.media}
                    image={props.displayPicture}
                    title={props.hospitalUnitName}
                />
                <CardContent>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Chip
                                    variant="outlined"
                                    size="medium"
                                    avatar={<Avatar>{String(props.slot.slotTime).replace(/(AM|PM)/g, "")}</Avatar>}
                                    label={String(props.slot.slotTime).replace(/[0-9]/g, '')}
                                    color="primary"

                                    deleteIcon={<DoneIcon />}
                                    style={{ marginRight: '1rem' }}
                                />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.slot.slotSize} secondary={"Available:" + (parseInt(props.slot.slotSize - props.slot.slotsAvailable))} />
                        </ListItem>
                    </List>
                </CardContent>
                {/* <CardActions disableSpacing>
                    <Typography align="center" style={{ paddingLeft: "1rem" }}>
                        Details
                            </Typography>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                       
                    </CardContent>
                </Collapse> */}
                <CardActions>
                    <SlotReservations emails={props.slot.slotReservedBy}></SlotReservations>
                </CardActions>
            </Card>
        </React.Fragment>
    )
}

SlotDisplay.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    api: state.api,
    auth: state.auth,
})


export default connect(mapStateToProps, {

})(SlotDisplay)
