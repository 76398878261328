import {
    SETERROR,
    SETERRORMESSAGE,
}

from './types';

export const setError = (currentErrorState) => dispatch => {
    dispatch({
        type: SETERROR,
        payload: currentErrorState
    })
}

export const setErrorMessage = (errorMessageTitle, errorMessageBody) => dispatch => {
    dispatch({
        type: SETERRORMESSAGE,
        payload:  {
            title: errorMessageTitle,
            body: errorMessageBody,
        }
    })
}