const firebaseConfig = {
    apiKey: "AIzaSyDJ9DV5JWpA8rMJZ4w4e2lMsptEb7WO4uY",
    authDomain: "covidresourcealloc.firebaseapp.com",
    databaseURL: "https://covidresourcealloc.firebaseio.com",
    projectId: "covidresourcealloc",
    storageBucket: "covidresourcealloc.appspot.com",
    messagingSenderId: "916369021750",
    appId: "1:916369021750:web:1c5c192d54e218f8bef197",
    measurementId: "G-7FNKNMMJHK"
  };

export default firebaseConfig;