import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Icon from '@material-ui/core/Icon';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'react-redux-firebase';
import {
  api_reserveSlot
} from '../../actions/APIActions';
import { SLOTTIMES } from './../../settings/slots';
var moment = require('moment');

var slotTimes = SLOTTIMES

const open = true;
const messages = [
  {
    time: "10AM",
    slotSize: 25,
    slotsAvailable: 6,
    person: '/static/images/avatar/5.jpg',
  },
  {
    time: "11AM",
    slotSize: 25,
    slotsAvailable: 5,
    person: '/static/images/avatar/1.jpg',
  },
  {
    time: "12PM",
    slotSize: 25,
    slotsAvailable: 14,
    person: '/static/images/avatar/2.jpg',
  },
  {
    time: "3PM",
    slotSize: 25,
    slotsAvailable: 12,
    person: '/static/images/avatar/3.jpg',
  },
  {
    time: "4PM",
    slotSize: 25,
    slotsAvailable: 15,
    person: '/static/images/avatar/4.jpg',
  },
  {
    time: "5PM",
    slotSize: 25,
    slotsAvailable: 1,
    person: '/static/images/avatar/5.jpg',
  },
  {
    time: "6PM",
    slotSize: 25,
    slotsAvailable: 25,
    person: '/static/images/avatar/1.jpg',
  },
];

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function SlotsDisplay1() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          Slots
        </Typography>
        <List className={classes.list}>
          {messages.map(({ time, slotSize, slotsAvailable, person }) => (
            <React.Fragment key={time}>
              {time === 1 && <ListSubheader className={classes.subheader}>Open</ListSubheader>}
              {/* {time === 3 && <ListSubheader className={classes.subheader}>Yesterday</ListSubheader>} */}
              <ListItem button>
                <ListItemAvatar>
                  {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                  <Chip
                    variant="outlined"
                    size="medium"
                    avatar={<Avatar>{String(time).replace(/(AM|PM)/g, "")}</Avatar>}
                    label={String(time).replace(/[0-9]/g, '')}
                    color="primary"

                    deleteIcon={<DoneIcon />}
                    style={{ marginRight: '1rem' }}
                  />
                </ListItemAvatar>
                <ListItemText primary={"Capacity:" + slotSize} secondary={"Available:" + parseInt(slotSize - slotsAvailable)} />
                <ListItemSecondaryAction>
                  {slotsAvailable < slotSize
                    ?
                    (<Button onClick={() => { reserveSlot("11PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                    :
                    (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                  }
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Paper>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="open drawer">
            <MenuIcon />
          </IconButton>
          <Fab color="secondary" aria-label="up" className={classes.fabButton} onClick={toggleDrawer("bottom", true)}>
            <AddIcon />
          </Fab>
          <SwipeableDrawer
            anchor={"bottom"}
            open={state["bottom"]}
            onClose={toggleDrawer("bottom", false)}
            onOpen={toggleDrawer("bottom", true)}
          >
            hi
    </SwipeableDrawer>
          <div className={classes.grow} />
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
          <IconButton edge="end" color="inherit">
            <MoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

function SlotsDisplay(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //slotTimes = props.api.selectedStoreDetails.slotTimings

  const toggleDrawer = (anchor, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  if (isEmpty(props.api.selectedStoreDetails)) {
    return (
      <React.Fragment>

      </React.Fragment>
    )
  }

  else if (props.auth.unbanTime !== "" && props.auth.reserveLock !== false) {
    return (
      <Typography className={classes.text} variant="h4" gutterBottom style={{ marginBottom: '7rem' }}>
        {
          props.auth.unbanTime !== "" && props.auth.reserveLock !== false
            ?
            "Can't book anymore slots today."
            : ""
        }
      </Typography>
    )
  }

  else {
    return (
      <React.Fragment>
        <CssBaseline />
        <Paper square className={classes.paper} >
          <Typography className={classes.text} variant="h4" gutterBottom>
            {props.api.selectedStoreDetails.displayName}
            <br></br>
            <Typography className={classes.text} variant="h5" gutterBottom>
          <b>{"@ " + props.api.selectedStoreDetails.address}</b></Typography>
          </Typography>
          <Typography className={classes.text} variant="h6" gutterBottom>
          {"Status: "}{props.api.selectedStoreDetails.openCheck === true ? <b>Open</b> : <b>Closed</b>}
          </Typography>
          {
            props.api.selectedStoreDetails.openCheck === true
              ?
              (
                <List className={classes.list}>
                  {/* {SlotItem5AM((props))} */}
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("5AM") && moment("5AM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot5AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot5AM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + (parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot5AM.slotsAvailable))} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot5AM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "5AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("6AM") && moment("6AM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot6AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot6AM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot6AM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot6AM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "6AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("7AM") && moment("7AM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot7AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot7AM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot7AM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot7AM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "7AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("8AM") && moment("8AM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot8AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot8AM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot8AM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot8AM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "8AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("9AM") && moment("9AM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot9AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot9AM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot9AM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot9AM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "9AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("10AM") && moment("10AM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot10AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot10AM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot10AM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot10AM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "10AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("11AM") && moment("11AM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot11AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot11AM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot11AM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot11AM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "11AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("12PM") && moment("12AM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot12PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot12PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot12PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot12PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "12PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("1PM") && moment("1PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot1PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot1PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot1PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot1PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "1PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("2PM") && moment("2PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot2PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot2PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot2PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot2PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "2PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("3PM") && moment("3PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot3PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot3PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot3PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot3PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "3PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("4PM") && moment("4PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot4PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot4PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot4PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot4PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "4PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("5PM") && moment("5PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot5PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot5PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot5PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot5PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "5PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("6PM") && moment("6PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot6PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot6PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot6PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot6PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "6PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("7PM") && moment("7PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot7PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot7PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot7PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot7PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "7PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("8PM") && moment("8PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot8PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot8PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot8PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot8PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "8PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("9PM") && moment("9PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot9PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot9PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot9PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot9PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "9PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("10PM") && moment("10PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot10PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot10PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot10PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot10PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "10PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  {
                    props.api.selectedStoreDetails.slotTimings.includes("11PM") && moment("11PM", "HHa").isSameOrAfter(moment())
                      ? (
                        <React.Fragment>
                          <ListItem button>
                            <ListItemAvatar>
                              {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                              <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot11PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                label={String(props.api.selectedStoreDetails.slots.slot11PM.time).replace(/[0-9]/g, '')}
                                color="primary"

                                deleteIcon={<DoneIcon />}
                                style={{ marginRight: '1rem' }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot11PM.slotsAvailable)} />
                            <ListItemSecondaryAction>
                              {props.api.selectedStoreDetails.slots.slot11PM.slotsAvailable < props.api.selectedStoreDetails.slotSize
                                ?
                                (<Button onClick={() => { reserveSlot(props, "11PM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                :
                                (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                              }
                            </ListItemSecondaryAction>
                          </ListItem>
                        </React.Fragment>
                      )
                      : (<React.Fragment></React.Fragment>)
                  }
                  <React.Fragment>
                    <ListItem>
                      <center>No more Slots Available</center>
              </ListItem>
                  </React.Fragment>
                </List>
              )
              :
              (
                <div></div>
              )
          }
        </Paper>
      </React.Fragment>
    );
  }
}

function reserveSlot(props, time) {
  console.log("Reserving for " + time)
  props.api_reserveSlot(props.api.selectedState.selectedState, props.api.selectedCity.selectedCity, props.api.selectedArea.selectedArea, props.api.selectedLocation.selectedLocation, props.api.selectedStore.selectedStore, time, props.auth.UserAuthData.email)
}


SlotsDisplay.propTypes = {
  api: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  api_reserveSlot: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  api: state.api,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  api_reserveSlot
})(SlotsDisplay);