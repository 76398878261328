import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from "@material-ui/pickers";
import { TimePicker } from "@material-ui/pickers";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import SlotDetails from './SlotDetails';

import {
    getTestsList_MED,
    setTestCategoryName_MED,
    getTests_MED,
    setTestName_MED,
    setDate_MED,
    setTime_MED,
    getSlots_MED
} from '../../../actions/APIActions';

const useStyles = makeStyles(theme => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: "5rem",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
    root: {
        maxWidth: 345,
        textAlign: 'left',
        margin: 'auto'
    },
}));

export const SecondaryChooser = (props) => {
    const classes = useStyles();
    const [testType, setTestType] = React.useState('');
    const [test, setTest] = React.useState('');
    const [date, changeDate] = useState(new Date());
    const [dateT, setDate] = React.useState('');
    const [time, changeTime] = useState(new Date());
    const [timeT, setTime] = React.useState('');

    const today = new Date();
    console.log("Date, Time: ", date, time, )

    const handleDateChange = event => {
        console.log("event", event._d);
        changeDate(event);
        setDate((event._d.getMonth() + 1) + "-" + event._d.getDate() + "-" + event._d.getFullYear());
        props.setDate_MED((event._d.getMonth() + 1) + "-" + event._d.getDate() + "-" + event._d.getFullYear());
        console.log((event._d.getMonth() + 1) + "-" + event._d.getDate() + "-" + event._d.getFullYear());
    }

    const handleTimeChange = event => {
        console.log("event", event._d.getDate());
        changeTime(event)
        let h = event._d.getHours() % 12 || 12
        let amPm = (event._d.getHours() < 12 || event._d.getHours() === 24) ? "AM" : "PM";
        setTime(h + amPm);
        props.setTime_MED(h + amPm);
        console.log(h + amPm);
        props.getSlots_MED(props.api.selectedStateMED.selectedState,
            props.api.selectedCityMED.selectedCity,
            props.api.selectedAreaMED.selectedArea,
            props.api.selectedLocationMED.selectedLocation,
            props.api.selectedHospital.selectedHospital, testType, test, h + amPm, props.api.dateMED.dateMED)
    }

    const handleTestTypeChange = event => {
        console.log(props.api.selectedStateMED.selectedState,
            props.api.selectedCityMED.selectedCity,
            props.api.selectedAreaMED.selectedArea,
            props.api.selectedLocationMED.selectedLocation,
            props.api.selectedHospital.selectedStore,event.target.value)
        setTestType(event.target.value);
        props.setTestCategoryName_MED(event.target.value);
        props.getTests_MED(props.api.selectedStateMED.selectedState,
            props.api.selectedCityMED.selectedCity,
            props.api.selectedAreaMED.selectedArea,
            props.api.selectedLocationMED.selectedLocation,
            props.api.selectedHospital.selectedHospital,
            event.target.value)
        // props.setStateName_MED(event.target.value);
        // props.getCityList_MED(event.target.value);
    };

    const handleTestChange = event => {
        console.log(event.target.value)
        setTest(event.target.value);
        props.setTestName_MED(event.target.value);
        // props.setStateName_MED(event.target.value);
        // props.getCityList_MED(event.target.value);
    };
    //const limit = new today.setDate(date.getDate() + 15);

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper square className={classes.paper} style={{ "background": "#212121" }}>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: '2rem' }}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Hospital</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="StoreSelect"
                        value={testType}
                        onChange={handleTestTypeChange}
                        label="Store"
                    >
                        {
                            props.api.testTypes.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Hospital</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="StoreSelect"
                        value={test}
                        onChange={handleTestChange}
                        label="Store"
                    >
                        {
                            props.api.tests.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Typography className={classes.text} variant="h5" align="left">Select Appointment Date</Typography>
                    <br></br>
                    <DatePicker
                        autoOk
                        //orientation="landscape"
                        variant="static"
                        openTo="date"
                        value={date}
                        onChange={handleDateChange}
                        minDate={today}
                        maxDate={today.getTime() + (60*60*24*1000*8)}
                    />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Typography className={classes.text} variant="h5" align="left">Select Appointment Time</Typography>
                    <br></br>
                    <TimePicker
                        autoOk
                        ampm={false}
                        variant="static"
                        //orientation="landscape"
                        openTo="minutes"
                        value={time}
                        onChange={handleTimeChange}
                        //minutesStep="60"
                        views={["hours"]}
                    />
                </FormControl>
                <SlotDetails></SlotDetails>
            </Paper>
        </React.Fragment>
    )
}

SecondaryChooser.propTypes = {
    auth: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    getTestsList_MED: PropTypes.func.isRequired,
    setTestCategoryName_MED: PropTypes.func.isRequired,
    getTests_MED: PropTypes.func.isRequired,
    setTestName_MED: PropTypes.func.isRequired,
    getSlots_MED: PropTypes.func.isRequired,
    setDate_MED: PropTypes.func.isRequired,
    setTime_MED: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    api: state.api
})

export default connect(mapStateToProps, {
    getTestsList_MED,
    setTestCategoryName_MED,
    getTests_MED,
    setTestName_MED,
    setDate_MED,
    setTime_MED,
    getSlots_MED
})(SecondaryChooser)
