import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import UnitDisplay from './unitDisplay';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
}));
export const UnitsList = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <ExpansionPanel style={{ backgroundColor: "#303030", marginBottom: '1rem' }}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Click to View Your Hospital's Units 🏥</Typography>
                </ExpansionPanelSummary>
                {props.api.hospitalUnitList === "" ? (
                    <React.Fragment />
                )
                    :
                    props.api.hospitalUnitList.map((
                        {
                            hospitalId,
                            hospitalName,
                            hospitalUnitName,
                            hospitalUnitCategoryName,
                            address,
                            displayDescription,
                            displayPicture,
                            timings,
                            slotSize,
                            openCheck,
                            workerIds,
                        }) => (
                            // <h1>{hospitalName}</h1>
                            <UnitDisplay
                                hospitalId={hospitalId}
                                hospitalName={hospitalName}
                                hospitalUnitName={hospitalUnitName}
                                hospitalUnitCategoryName={hospitalUnitCategoryName}
                                address={address}
                                displayDescription={displayDescription}
                                displayPicture={displayPicture}
                                timings={timings}
                                slotSize={slotSize}
                                openCheck={openCheck}
                                workerIds={workerIds}
                                stateName={props.api.selectedHospitalDetails.stateName}
                                cityName={props.api.selectedHospitalDetails.cityName}
                                areaName={props.api.selectedHospitalDetails.areaName}
                                locationName={props.api.selectedHospitalDetails.locationName}
                            ></UnitDisplay>
                        ))
                }
            </ExpansionPanel>
        </React.Fragment >
    )
}

UnitsList.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    api: state.api,
    auth: state.auth,
})

export default connect(mapStateToProps, {

})(UnitsList)
