import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import down from './../../down.svg'

import {
    getStateList,
    setStateName,
    getCityList,
    setCityName,
    getAreaList,
    setAreaName,
    getLocationList,
    setLocationName,
    getStoreList,
    setStoreName,
    getStoreDetails
} from '../../actions/APIActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'react-redux-firebase';

var moment = require('moment');

var i = 0
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
}));

function MainChooser(props) {
    const classes = useStyles();
    const [state, setState] = React.useState('');
    const [city, setCity] = React.useState('');
    const [area, setArea] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [store, setStore] = React.useState('');
    const handleStateChange = event => {
        setState(event.target.value);
        props.setStateName(event.target.value);
        props.getCityList(event.target.value);
    };
    const handleCityChange = event => {
        setCity(event.target.value);
        props.setCityName(event.target.value);
        props.getAreaList(state, event.target.value);
    };
    const handleAreaChange = event => {
        setArea(event.target.value);
        props.setAreaName(event.target.value);
        props.getLocationList(state, city, event.target.value);
        props.getStoreList(state, city, event.target.value, "");
    };
    const handleLocationChange = event => {
        setLocation(event.target.value);
        props.setLocationName(event.target.value);
        props.getStoreList(state, city, area, event.target.value);
    };
    const handleStoreChange = event => {
        setStore(event.target.value);
        props.setStoreName(event.target.value)
        props.getStoreDetails(state, city, area, location, event.target.value);
    };

    // if (isEmpty(props.editor.botList) === true){
    //     console.log("Empty")
    //     props.getBotlist()
    // }
    //TODO:check lists empty and send request
    if (isEmpty(props.api.stateList) === true && i == 0) {
        console.log("State List is Empty");
        props.getStateList();
        i++;
        return (
            <React.Fragment>

            </React.Fragment>
        )

        
    }

    else {



        return (
            <div style={{ marginBottom: '7rem' }}>
                <Typography className={classes.text} variant="h4" gutterBottom>
                    {
                        props.auth.unbanTime !== "" && props.auth.reserveLock !== false
                            ?
                            moment(props.auth.UserAuthData.activity.slot, "HHa").isSameOrAfter(moment()) === false ?
                                (<p>
                                    Hope you had a safe and peaceful shopping experience today <br /> (You can book again tomorrow)
                                </p>)
                                :
                                (<p>
                                    Your Slot has been Booked!<br /> View your booking from the menu down below 👇
                                </p>)
                            : "You can a book a slot for today down below 👇"
                    }
                </Typography>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select State</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="StateSelect"
                        value={state}
                        onChange={handleStateChange}
                        label="Location"
                    >
                        {
                            props.api.stateList.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select City</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="CitySelect"
                        value={city}
                        onChange={handleCityChange}
                        label="City"
                    >
                        {
                            props.api.cityList.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Area</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="AreaSelect"
                        value={area}
                        onChange={handleAreaChange}
                        label="Location"
                    >
                        {
                            props.api.areaList.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Location (Optional)</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="LocationSelect"
                        value={location}
                        onChange={handleLocationChange}
                        label="Location"
                    >
                        {
                            props.api.locationList.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>
                {/* <Typography className={classes.text} variant="p" gutterBottom>
                You can view Store List after selecting until Area as well</Typography>
            <br></br> */}
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Store</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="StoreSelect"
                        value={store}
                        onChange={handleStoreChange}
                        label="Store"
                    >
                        {
                            props.api.storeList.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>
                <br></br>
                <img src={down} className="App-Down" alt="logo" role="button" onClick={() => { window.scrollTo(0, document.body.scrollHeight) }} />
            </div>
        );
    }
}

MainChooser.propTypes = {
    auth: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    getStateList: PropTypes.func.isRequired,
    setStateName: PropTypes.func.isRequired,
    getCityList: PropTypes.func.isRequired,
    setCityName: PropTypes.func.isRequired,
    getAreaList: PropTypes.func.isRequired,
    setAreaName: PropTypes.func.isRequired,
    getLocationList: PropTypes.func.isRequired,
    setLocationName: PropTypes.func.isRequired,
    getStoreList: PropTypes.func.isRequired,
    setStoreName: PropTypes.func.isRequired,
    getStoreDetails: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    api: state.api,
});

export default connect(mapStateToProps, {
    getStateList,
    setStateName,
    getCityList,
    setCityName,
    getAreaList,
    setAreaName,
    getLocationList,
    setLocationName,
    getStoreList,
    setStoreName,
    getStoreDetails
})(MainChooser);
