import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Button, Switch } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Icon from '@material-ui/core/Icon';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'react-redux-firebase';
import {
    updateTimings
} from '../../actions/APIActions';
import { SLOTTIMES } from './../../settings/slots';
import Checkbox from '@material-ui/core/Checkbox';
var moment = require('moment');

var slotTimes = SLOTTIMES

const open = true;
const messages = [
    {
        time: "10AM",
        slotSize: 25,
        slotsAvailable: 6,
        person: '/static/images/avatar/5.jpg',
    },
    {
        time: "11AM",
        slotSize: 25,
        slotsAvailable: 5,
        person: '/static/images/avatar/1.jpg',
    },
    {
        time: "12PM",
        slotSize: 25,
        slotsAvailable: 14,
        person: '/static/images/avatar/2.jpg',
    },
    {
        time: "3PM",
        slotSize: 25,
        slotsAvailable: 12,
        person: '/static/images/avatar/3.jpg',
    },
    {
        time: "4PM",
        slotSize: 25,
        slotsAvailable: 15,
        person: '/static/images/avatar/4.jpg',
    },
    {
        time: "5PM",
        slotSize: 25,
        slotsAvailable: 1,
        person: '/static/images/avatar/5.jpg',
    },
    {
        time: "6PM",
        slotSize: 25,
        slotsAvailable: 25,
        person: '/static/images/avatar/1.jpg',
    },
];

const useStyles = makeStyles(theme => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function SlotsEditor(props) {
    const classes = useStyles();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [selectedSlot, setSelectedSlot] = React.useState('');

    const handleVisitedToggle = (number) => event => {
        console.log("recieved: ", event.target.checked)
        document.getElementById(number + 'check').checked = event.target.checked;
        props.updateVisited(props.api.selectedStoreDetails._id, number, event.target.checked, selectedSlot)
    }

    const timingsOnUpdate = (number) => event => {
        var timings = props.api.selectedStoreDetails.slotTimings;
        //timings = timings.split("\n");
        console.log(timings);
        if (event.target.checked === true) {
            console.log("True", number);
            if(timings.includes(number) === false)
            timings.push(number)
            console.log(timings);
        }
        else {
            console.log("False", number)
            const indexOfTime = timings.indexOf(number);
            if (indexOfTime > -1) {
                timings.splice(indexOfTime, 1);
            }
            console.log(timings);
        }
        //props.api.selectedStoreDetails.slotTimings = timings;
        props.updateTimings(props.auth.UserAuthData.shopId, timings);
    }

    const toggleDrawer = (anchor, open, details, time) => event => {
        if (open === true) {
            setSelectedSlot(time)
            showAttendees(details, time)
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const showAttendees = (details, time) => {
        //console.log("details ", details, "time ", time)
        console.log(details.slots["slot" + time].slotsReservedby)
        console.log(details.slots["slot" + time].slotsAttendedby);
    }

    if (isEmpty(props.api.selectedStoreDetails)) {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }

    // else if (props.auth.unbanTime !== "" && props.auth.reserveLock !== false) {
    //     return (
    //         <Typography className={classes.text} variant="h4" gutterBottom style={{ marginBottom: '7rem' }}>
    //             {
    //                 props.auth.unbanTime !== "" && props.auth.reserveLock !== false
    //                     ?
    //                     "Can't book anymore slots today."
    //                     : ""
    //             }
    //         </Typography>
    //     )
    // }

    else {
        return (
            <React.Fragment>
                <CssBaseline />
                <Paper square className={classes.paper} >
                    <Typography className={classes.text} variant="h4" gutterBottom>
                        {"Slot Timings Editor"}
                    </Typography>
                    {/* <Typography className={classes.text} variant="h6" gutterBottom>
                        {"Reload to see changes"}
                    </Typography> */}
                    {
                        props.api.selectedStoreDetails.openCheck !== undefined
                            ?
                            (
                                <List className={classes.list}>

                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot5AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot5AM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("5AM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("5AM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("5AM") ? true : false} onChange={timingsOnUpdate("5AM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot6AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot6AM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("6AM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("6AM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("6AM") ? true : false} onChange={timingsOnUpdate("6AM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot7AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot7AM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("7AM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("7AM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("7AM") ? true : false} onChange={timingsOnUpdate("7AM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot8AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot8AM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("8AM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("8AM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("8AM") ? true : false} onChange={timingsOnUpdate("8AM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot9AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot9AM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("9AM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("9AM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("9AM") ? true : false} onChange={timingsOnUpdate("9AM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot10AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot10AM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("10AM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("10AM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("10AM") ? true : false} onChange={timingsOnUpdate("10AM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot11AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot11AM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("11AM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("11AM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("11AM") ? true : false} onChange={timingsOnUpdate("11AM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot12PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot12PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("12PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("12PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("12PM") ? true : false} onChange={timingsOnUpdate("12PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot1PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot1PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("1PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("1PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("1PM") ? true : false} onChange={timingsOnUpdate("1PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot2PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot2PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("2PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("2PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("2PM") ? true : false} onChange={timingsOnUpdate("2PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot3PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot3PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("3PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("3PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("3PM") ? true : false} onChange={timingsOnUpdate("3PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot4PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot4PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("4PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("4PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("4PM") ? true : false} onChange={timingsOnUpdate("4PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot5PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot5PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("5PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("5PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("5PM") ? true : false} onChange={timingsOnUpdate("5PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot6PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot6PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("6PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("6PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("6PM") ? true : false} onChange={timingsOnUpdate("6PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot7PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot7PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("7PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("7PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("7PM") ? true : false} onChange={timingsOnUpdate("7PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot8PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot8PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("8PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("8PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("8PM") ? true : false} onChange={timingsOnUpdate("8PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot9PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot9PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("9PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("9PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("9PM") ? true : false} onChange={timingsOnUpdate("9PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot10PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot10PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("10PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("10PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("10PM") ? true : false} onChange={timingsOnUpdate("10PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot11PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                    label={String(props.api.selectedStoreDetails.slots.slot11PM.time).replace(/[0-9]/g, '')}
                                                    color="primary"

                                                    deleteIcon={<DoneIcon />}
                                                    style={{ marginRight: '1rem' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={props.api.selectedStoreDetails.slotTimings.includes("11PM") ? "In Use" : "Not in Use"} secondary={props.api.selectedStoreDetails.slotTimings.includes("11PM") ? "Click to Disable" : "Click to Enable"} />
                                            <ListItemSecondaryAction>
                                                <Switch color="primary" defaultChecked={props.api.selectedStoreDetails.slotTimings.includes("11PM") ? true : false} onChange={timingsOnUpdate("11PM")} ></Switch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </React.Fragment>
                                </List>
                            )
                            :
                            (
                                <div></div>
                            )
                    }
                    <React.Fragment>
                        <SwipeableDrawer
                            anchor={"bottom"}
                            open={state["bottom"]}
                            onClose={toggleDrawer("bottom", false)}
                            onOpen={toggleDrawer("bottom", true)}
                        >
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <List dense className={classes.root} style={{ marginBottom: '1.5rem' }}>
                                        <ListItem key={0}>
                                            <ListItemText>
                                                <Typography className={classes.text} variant="h4" >
                                                    {selectedSlot}
                                                </Typography>
                                                <Typography className={classes.text} variant="h6">
                                                    {props.api.selectedStoreDetails.slots["slot" + selectedSlot] !== undefined ?
                                                        props.api.selectedStoreDetails.slots["slot" + selectedSlot].slotsAvailable + " / " + props.api.selectedStoreDetails.slotSize + " Slots Filled"
                                                        : ""}
                                                </Typography>
                                                <Typography className={classes.text} variant="h6" gutterBottom>
                                                    {props.api.selectedStoreDetails.slots["slot" + selectedSlot] !== undefined ?
                                                        props.api.selectedStoreDetails.slots["slot" + selectedSlot].slotsReservedby.filter(value => value.visited === true).length + " / " + props.api.selectedStoreDetails.slots["slot" + selectedSlot].slotsReservedby.length + " Visited"
                                                        : ""}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <Divider style={{ marginBottom: '1.5rem' }} />
                                        {props.api.selectedStoreDetails.slots["slot" + selectedSlot] !== undefined
                                            ?
                                            props.api.selectedStoreDetails.slots["slot" + selectedSlot].slotsReservedby.map(({ _id, number, visited }) => (
                                                <React.Fragment>
                                                    <ListItem key={number} button>
                                                        <ListItemAvatar>
                                                            <Avatar
                                                            >{number}</Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText id={number + "text"} primary={"Name Hidden"} />
                                                        <ListItemSecondaryAction id={number + "action"}>
                                                            <Checkbox
                                                                id={number + 'check'}
                                                                edge="end"
                                                                onClick={handleVisitedToggle(number)}
                                                                defaultChecked={visited}
                                                                color="default"
                                                                inputProps={{ 'aria-label': 'Checkbox A' }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                </React.Fragment>
                                            ))
                                            :
                                            "undefined"}
                                    </List>
                                </CardActionArea>
                            </Card></SwipeableDrawer>
                    </React.Fragment>
                </Paper>
            </React.Fragment>
        );
    }
}

SlotsEditor.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    updateTimings: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    api: state.api,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    updateTimings
})(SlotsEditor);