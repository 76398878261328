import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
    api_reserveSlot_MED
} from '../../../actions/APIActions';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        textAlign: 'left',
        margin: 'auto'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
    },
}));

const SlotReserveButton = (props) => {
    const classes = useStyles();

    const reserveSlot = () => {

        if (props.props.api.dependencyMED === "SELF") {
            props.props.api_reserveSlot_MED(
                props.props.auth.UserAuthData.email,
                props.props.auth.UserAuthData.displayName,
                props.props.auth.UserAuthData.phone,
                props.props.api.selectedStateMED.selectedState,
                props.props.api.selectedCityMED.selectedCity,
                props.props.api.selectedAreaMED.selectedArea,
                props.props.api.selectedLocationMED.selectedLocation,
                props.props.api.selectedHospital.selectedHospital,
                props.props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalId,
                props.props.api.selectedTestType.selectedTestType,
                props.props.api.selectedTest.selectedTest,
                props.props.api.timeMED.timeMED,
                props.props.api.dateMED.dateMED,
                props.props.api.dependencyMED,
                props.props.auth.UserAuthData.gender,
                props.props.auth.UserAuthData.displayName,
            )
        }

        else {
            props.props.api_reserveSlot_MED(
                props.props.auth.UserAuthData.email,
                props.props.auth.UserAuthData.displayName,
                props.props.api.phNoMED,
                props.props.api.selectedStateMED.selectedState,
                props.props.api.selectedCityMED.selectedCity,
                props.props.api.selectedAreaMED.selectedArea,
                props.props.api.selectedLocationMED.selectedLocation,
                props.props.api.selectedHospital.selectedHospital,
                props.props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalId,
                props.props.api.selectedTestType.selectedTestType,
                props.props.api.selectedTest.selectedTest,
                props.props.api.timeMED.timeMED,
                props.props.api.dateMED.dateMED,
                props.props.api.dependencyMED,
                props.props.api.genderMED,
                props.props.api.nameMED,
            )
        }


    }

    if (containsObj(props.props.auth.UserAuthData.email, props.props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotReservedBy) == true) {
        return (
            <React.Fragment>
                <React.Fragment>
                    <Button onClick={() => { }}
                        disabled
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    //endIcon={<AddIcon />}
                    >Reserved by You!</Button>
                </React.Fragment>
            </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>
                <Button onClick={() => { reserveSlot(props, "5AM") }}
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    endIcon={<AddIcon />}>Reserve</Button>
            </React.Fragment>
        )
    }

}

const containsObj = (obj, list) => {
    for (const listitem of list) {
        if (listitem._id == obj) {
            console.log("Found ", listitem._id, obj)
            return true;
        }
    }

    console.log("Not Found")
    return false;
}

export const SlotDetails = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    //console.log(props.api.selectedHospitalDetails.selectedHospitalDetails.slot)

    const reserveSlot = () => {
        props.api_reserveSlot_MED(
            props.auth.UserAuthData.email,
            props.api.selectedStateMED.selectedState,
            props.api.selectedCityMED.selectedCity,
            props.api.selectedAreaMED.selectedArea,
            props.api.selectedLocationMED.selectedLocation,
            props.api.selectedHospital.selectedHospital,
            props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalId,
            props.api.selectedTestType.selectedTestType,
            props.api.selectedTest.selectedTest,
            props.api.timeMED.timeMED,
            props.api.dateMED.dateMED,
            props.api.dependencyMED,
        )
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    if (props.api.selectedHospitalDetails.selectedHospitalDetails != undefined && props.api.selectedHospitalDetails.success != false) {
        return (
            <React.Fragment>
                <Typography className={classes.text} variant="h5" align="center" style={{ marginTop: "1rem", marginBottom: "1rem" }}>Your Appointment</Typography>

                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                {props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalName == undefined ? "" : props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalName[0]}
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title={props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalName == undefined ? "" : props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalName}
                        subheader={props.api.selectedHospitalDetails.selectedHospitalDetails.slot == undefined ? "" : props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotDate + "@" + props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotTime}
                    />
                    <CardMedia
                        className={classes.media}
                        image={props.api.selectedHospitalDetails.selectedHospitalDetails.displayPicture == undefined ? "" : props.api.selectedHospitalDetails.selectedHospitalDetails.displayPicture}
                        title={props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalName == undefined ? "" : props.api.selectedHospitalDetails.selectedHospitalDetails.hospitalName[0]}
                    />
                    <CardContent>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Chip
                                        variant="outlined"
                                        size="medium"
                                        avatar={<Avatar>{String(props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotTime).replace(/(AM|PM)/g, "")}</Avatar>}
                                        label={String(props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotTime).replace(/[0-9]/g, '')}
                                        color="primary"

                                        deleteIcon={<DoneIcon />}
                                        style={{ marginRight: '1rem' }}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={"Capacity:" + props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotSize} secondary={"Available:" + (parseInt(props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotSize - props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotsAvailable))} />
                                <ListItemSecondaryAction>
                                    {/* {props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotsAvailable < props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotSize
                                    ?
                                    (<Button onClick={() => { reserveSlot(props, "5AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>)
                                    :
                                    (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                                } */}
                                </ListItemSecondaryAction>
                            </ListItem></List>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Short Info on Test
                </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        {/* <IconButton aria-label="add to favorites">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareIcon />
                            </IconButton> */}
                        <Typography align="center" style={{ paddingLeft: "1rem" }}>
                            Learn More
                            </Typography>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>About</Typography>
                            <Typography paragraph>
                                {props.api.selectedHospitalDetails.selectedHospitalDetails.displayDescription == undefined ? "" : props.api.selectedHospitalDetails.selectedHospitalDetails.displayDescription}
                            </Typography>
                            {/* <Typography paragraph>
                                Bio 1
                  </Typography>
                            <Typography paragraph>
                                Bio 2
                  </Typography> */}
                        </CardContent>
                    </Collapse>
                    <CardActions>
                        {/* <Button size="small" color="primary">
                            Book Appointment
                </Button> */}
                        {props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotsAvailable < props.api.selectedHospitalDetails.selectedHospitalDetails.slot.slotSize
                            ?
                            (
                                <SlotReserveButton props={props}></SlotReserveButton>
                                // <Button onClick={() => { reserveSlot(props, "5AM") }} variant="outlined" color="primary" className={classes.button} endIcon={<AddIcon />}>Reserve</Button>
                            )
                            :
                            (<Button variant="outlined" disabled color="primary" className={classes.button}>Reserved 🙁</Button>)
                        }
                        {/* <Button size="small" color="primary">
                  Learn More
                </Button> */}
                    </CardActions>
                </Card>
            </React.Fragment>
        )
    }
    if (props.api.selectedHospitalDetails.selectedHospitalDetails != undefined && props.api.selectedHospitalDetails.success == false) {
        return (
            <React.Fragment>
                <br>
                </br>
                <Typography paragraph>
                    No Slot Available at that time.
                </Typography>
                <TableContainer component={Paper}>
                    <Table stickyHeader className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Day</TableCell>
                                <TableCell align="right">Timings</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {"Monday"}
                                </TableCell>
                                {
                                    props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Monday.hours.length != 0 ?
                                        props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Monday.hours.map((hours) => (
                                            <TableCell key={hours}>
                                                {hours}
                                            </TableCell>
                                        ))
                                        :
                                        <TableCell key="Closed">
                                            {"Closed"}
                                        </TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {"Tuesday"}
                                </TableCell>
                                {
                                    props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Tuesday.hours.length != 0 ?
                                        props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Tuesday.hours.map((hours) => (
                                            <TableCell key={hours}>
                                                {hours}
                                            </TableCell>
                                        ))
                                        :
                                        <TableCell key="Closed">
                                            {"Closed"}
                                        </TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {"Wednesday"}
                                </TableCell>
                                {
                                    props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Wednesday.hours.length != 0 ?
                                        props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Wednesday.hours.map((hours) => (
                                            <TableCell key={hours}>
                                                {hours}
                                            </TableCell>
                                        ))
                                        :
                                        <TableCell key="Closed">
                                            {"Closed"}
                                        </TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {"Thursday"}
                                </TableCell>
                                {
                                    props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Thursday.hours.length != 0 ?
                                        props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Thursday.hours.map((hours) => (
                                            <TableCell key={hours}>
                                                {hours}
                                            </TableCell>
                                        ))
                                        :
                                        <TableCell key="Closed">
                                            {"Closed"}
                                        </TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {"Friday"}
                                </TableCell>
                                {
                                    props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Friday.hours.length != 0 ?
                                        props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Friday.hours.map((hours) => (
                                            <TableCell key={hours}>
                                                {hours}
                                            </TableCell>
                                        ))
                                        :
                                        <TableCell key="Closed">
                                            {"Closed"}
                                        </TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {"Saturday"}
                                </TableCell>
                                {
                                    props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Saturday.hours.length != 0 ?
                                        props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Saturday.hours.map((hours) => (
                                            <TableCell key={hours}>
                                                {hours}
                                            </TableCell>
                                        ))
                                        :
                                        <TableCell key="Closed">
                                            {"Closed"}
                                        </TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {"Sunday"}
                                </TableCell>
                                {
                                    props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Sunday.hours.length != 0 ?
                                        props.api.selectedHospitalDetails.selectedHospitalDetails.timings.Sunday.hours.map((hours) => (
                                            <TableCell key={hours}>
                                                {hours}
                                            </TableCell>
                                        ))
                                        :
                                        <TableCell key="Closed">
                                            {"Closed"}
                                        </TableCell>
                                }

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }

}

SlotDetails.propTypes = {
    auth: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    api_reserveSlot_MED: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    api: state.api
})

export default connect(mapStateToProps, {
    api_reserveSlot_MED,
})(SlotDetails)
