import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReportChooser from '../components/cop/dasboardChooser';
import PrimaryAppBar from '../components/PrimaryAppBar';
import Dashboard from '../components/cop/dashboard';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from './../logo.svg';

const useStyles = makeStyles({
    root: {
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
});

function CopPage(props) {
    const classes = useStyles();

    return (
        <div className="App">
            <PrimaryAppBar></PrimaryAppBar>
            <header className="App-header">
                <Typography className={classes.text} variant="h1">
                    Braggi
              </Typography>
                <Typography className={classes.text} variant="h6">
                    Shop Safely during Lockdowns
              </Typography>
                <img src={logo} className="App-logo" alt="logo" />
                <ReportChooser/>
            </header>
            <Dashboard></Dashboard>
        </div>
    )
}
CopPage.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {

})(CopPage);
