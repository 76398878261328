import {
    SETERROR,
    SETERRORMESSAGE,
} from './../actions/types';

const initialState = {
    error: false,
    errorMessage: {
        title: "",
        body: "",
    },
}

export default function (state = initialState, action) {
    switch (action.type) {

        case SETERROR:
            return {
                ...state,
                error: action.payload
            }

        case SETERRORMESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            }

        default:
            return state
    }
}
