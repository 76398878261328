import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { getStoreDetailsbyId, updateWorkers, opencloseShop } from './../../actions/APIActions';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
}));
var i = 0;
function InfoWidget(props) {
    const classes = useStyles();

    if (props.api.selectedStoreDetails === "" && i == 0) {
        props.getStoreDetailsbyId(props.auth.UserAuthData.shopId)
        i++;

        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
    else {

        return (
            <React.Fragment>
                <br></br>
                <Typography className={classes.text} variant="h5">
                    Store Management
                  </Typography>
                <br></br>
                <TextField id="outlined-basic" variant="outlined" label="Store Name" disabled value={props.api.selectedStoreDetails.displayName + ""}>
                </TextField>
                <br></br>

                <TextField id="outlined-basic1" variant="outlined" label="Slot Size" disabled value={props.api.selectedStoreDetails.slotSize + " Slots"}>
                </TextField>
                <br>
                </br>
                {/* <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <br></br> */}
            </React.Fragment>
        )
    }
}
InfoWidget.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getStoreDetailsbyId: PropTypes.func.isRequired,
    updateWorkers: PropTypes.func.isRequired,
    opencloseShop: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getStoreDetailsbyId, updateWorkers, opencloseShop
})(InfoWidget);
