import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import {
    getHospitalInfo,
    getHospitalUnits_Admin,
    editHospital,
} from './../../../actions/APIActions';
import UnitDisplay from './../unitDisplay';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
}));

var i = 0;
function RegisterHospital(props) {
    const classes = useStyles();

    const [description, setDescription] = React.useState(props.api.selectedHospitalDetails.displayDescription)
    const [picture, setPicture] = React.useState(props.api.selectedHospitalDetails.displayPicture)
    const [address, setAddress] = React.useState(props.api.selectedHospitalDetails.address)

    const descriptionOnUpdate = (event) => {
        console.log("Value: ", event.target.value);
        setDescription(event.target.value);
    }

    const updateHospitalDescription = () => {
        props.editHospital(
            props.api.selectedHospitalDetails._id,
            props.api.selectedHospitalDetails.stateName,
            props.api.selectedHospitalDetails.cityName,
            props.api.selectedHospitalDetails.areaName,
            props.api.selectedHospitalDetails.locationName,
            props.api.selectedHospitalDetails.hospitalName,
            {
                displayDescription: description
            }
        );
    }

    const pictureOnUpdate = (event) => {
        console.log("Value: ", event.target.value);
        setPicture(event.target.value);
    }

    const updateHospitalPicture = () => {
        props.editHospital(
            props.api.selectedHospitalDetails._id,
            props.api.selectedHospitalDetails.stateName,
            props.api.selectedHospitalDetails.cityName,
            props.api.selectedHospitalDetails.areaName,
            props.api.selectedHospitalDetails.locationName,
            props.api.selectedHospitalDetails.hospitalName,
            {
                displayPicture: picture
            }
        );
    }

    const addressOnUpdate = (event) => {
        console.log("Value: ", event.target.value);
        setAddress(event.target.value);
    }

    const updateHospitalAddress = () => {
        props.editHospital(
            props.api.selectedHospitalDetails._id,
            props.api.selectedHospitalDetails.stateName,
            props.api.selectedHospitalDetails.cityName,
            props.api.selectedHospitalDetails.areaName,
            props.api.selectedHospitalDetails.locationName,
            props.api.selectedHospitalDetails.hospitalName,
            {
                address: address
            }
        );
    }

    if (props.api.selectedHospitalDetails === ""  && props.api.hospitalUnitList.length === 0 && i == 0) {
        //document.title = `You clicked times`;
        props.getHospitalInfo(props.auth.UserAuthData.hospitalId);
        props.getHospitalUnits_Admin(props.auth.UserAuthData.hospitalId);
        i++;
        return (
            <React.Fragment>
                <Skeleton animation="wave" style={{
                    width: '25ch',
                    height: "4rem"
                }} />
                <Skeleton animation="wave" style={{
                    width: '25ch',
                    height: "4rem"
                }} />
                <Skeleton animation="wave" style={{
                    width: '25ch',
                    height: "4rem"
                }} />
                <Skeleton animation="wave" style={{
                    width: '25ch',
                    height: "4rem"
                }} />
            </React.Fragment>
        )
    }

    else {

        return (
            <React.Fragment>
                <br></br>
                <Typography className={classes.text} variant="h5">
                    Hospital Management
                  </Typography>
                <br></br>
                {
                    props.api.selectedHospitalDetails.displayName ?
                        (
                            <React.Fragment>
                                <TextField id="outlined-basic" variant="outlined" label="Hospital Name" disabled value={props.api.selectedHospitalDetails.displayName + ""}>
                                </TextField>
                                <br></br>
                            </React.Fragment>)
                        :
                        (<Skeleton animation="wave" style={{
                            width: '25ch',
                            height: "4rem"
                        }} />)

                }
                {
                    props.api.selectedHospitalDetails.displayDescription
                        ?
                        (
                            <React.Fragment>
                                <TextField id="hosdesc" variant="outlined" InputLabelProps={{
                                    shrink: true,
                                }} label="Hospital Description"
                                    onChange={descriptionOnUpdate}
                                    multiline
                                    defaultValue={props.api.selectedHospitalDetails.displayDescription !== undefined ? props.api.selectedHospitalDetails.displayDescription : ""}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Save"
                                                onClick={updateHospitalDescription}
                                                edge="end"
                                            >
                                                <SaveIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                >
                                </TextField>
                                <br></br>
                            </React.Fragment>
                        )
                        :
                        (<Skeleton animation="wave" style={{
                            width: '25ch',
                            height: "4rem"
                        }} />)
                }
                {
                    props.api.selectedHospitalDetails.displayPicture
                        ?
                        (<React.Fragment><TextField id="hospic"
                            multiline
                            onChange={pictureOnUpdate}
                            variant="outlined" label="Hospital Picture URL"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={props.api.selectedHospitalDetails.displayPicture !== undefined ? props.api.selectedHospitalDetails.displayPicture : ""}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Save"
                                        onClick={updateHospitalPicture}
                                        edge="end"
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        >
                        </TextField><br></br>
                        </React.Fragment>)
                        :
                        (<Skeleton animation="wave" style={{
                            width: '25ch',
                            height: "4rem"
                        }} />)
                }
                {props.api.selectedHospitalDetails.address
                    ?
                    (<React.Fragment>
                        <TextField id="hosadd"
                            multiline
                            onChange={addressOnUpdate}
                            variant="outlined" label="Hospital Address"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={props.api.selectedHospitalDetails.address !== undefined ? props.api.selectedHospitalDetails.address : ""}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Save"
                                        onClick={updateHospitalAddress}
                                        edge="end"
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        >
                        </TextField>
                        <br></br>
                    </React.Fragment>)
                    :
                    (<Skeleton animation="wave" style={{
                        width: '25ch',
                        height: "4rem"
                    }} />)}
                <hr></hr>

            </React.Fragment>
        )
    }
}

RegisterHospital.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getHospitalInfo: PropTypes.func.isRequired,
    getHospitalUnits_Admin: PropTypes.func.isRequired,
    editHospital: PropTypes.func.isRequired,
}


const mapStateToProps = state => ({
    api: state.api,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getHospitalInfo,
    getHospitalUnits_Admin,
    editHospital,
})(RegisterHospital);
