import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainChooser from '../../components/medical/customer/MainChooser';
import SecondaryChooser from '../../components/medical/customer/SecondaryChooser';
import TicketDisplay from '../../components/customer/TicketDisplay';
import PrimaryAppBar from '../../components/PrimaryAppBar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from './../../logo.svg';
import RegisterHospital from './../../components/medical/owner/registerHospital';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {
    getHospitalInfo,
} from './../../actions/APIActions';

import UnitsList from './../../components/medical/unitsList';
import UnitAdderDrawer from '../../components/medical/unitAdderDrawer';

const useStyles = makeStyles({
    root: {
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
});

function OwnerPage(props) {
    const classes = useStyles();

    const handleClickOpen = () => {
    };

    return (
        <div className="App">
            <PrimaryAppBar></PrimaryAppBar>
            <header className="App-header">
                <Typography className={classes.text} variant="h1">
                    Braggi
              </Typography>
                <Typography className={classes.text} variant="h6">
                    Shop Safely during Lockdowns - Admin
              </Typography>
                <img src={logo} className="App-logo" alt="logo" />
                <RegisterHospital></RegisterHospital>
            </header>
            <UnitsList style={{ marginTop: '1rem' }}></UnitsList>
            <UnitAdderDrawer></UnitAdderDrawer>
        </div>
    )
}
OwnerPage.propTypes = {
    auth: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    getHospitalInfo: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    api: state.auth,
    error: state.error,
});

export default connect(mapStateToProps, {
    getHospitalInfo,
})(OwnerPage);
