import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainChooser from '../components/customer/MainChooser';
import SlotsDisplay from '../components/customer/SlotsDisplay';
import TicketDisplay from '../components/customer/TicketDisplay';
import PrimaryAppBar from '../components/PrimaryAppBar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from './../logo.svg';

const useStyles = makeStyles({
    root: {
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
});

function CustomerPage(props) {
    const classes = useStyles();

    return (
        <div className="App">
            <PrimaryAppBar></PrimaryAppBar>
            <header className="App-header">
                <Typography className={classes.text} variant="h1">
                    Braggi
              </Typography>
                <Typography className={classes.text} variant="h6">
                    Shop Safely during Lockdowns
              </Typography>
                <img src={logo} className="App-logo" alt="logo" />
                <MainChooser></MainChooser>
            </header>
            {/*<TabPanel></TabPanel>*/}
            <SlotsDisplay></SlotsDisplay>
            <TicketDisplay></TicketDisplay>
        </div>
    )
}
CustomerPage.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {

})(CustomerPage);
