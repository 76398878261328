import React, { Component } from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save'
import SendIcon from '@material-ui/icons/Send';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

import {
    setWorkers,
} from './../../actions/APIActions';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
}));


export const WorkerAdder = (props) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [checked, setChecked] = React.useState(props.workerIds);

    const handleToggle = (value) => () => {
        console.log("Value: ", value)
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        console.log(newChecked);
    };

    const [OpenCheck, setOpenChecked] = React.useState(props.openCheck);
    const [textValue, setValue] = React.useState("");
    const [formError, setFormError] = React.useState(false);

    const handleChange = (prop) => (event) => {
        setValue(event.target.value);
        if (validateEmail(event.target.value)) {
            setFormError(false)
        }
        else {
            console.log("Wrong Email Format")
            setFormError(true)
        }
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const handleClick = () => {
        if (validateEmail(textValue)) {
            console.log("Clicked")
            console.log("Value: ", textValue)
            const currentIndex = checked.indexOf(textValue);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(textValue);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            setChecked(newChecked);

            console.log("Workers: ", props.api.workers);
            let workersList = props.api.workers;
            workersList = newChecked;
            console.log("Updated Workers: ", workersList);

            props.setWorkers(
                workersList
            )

            console.log(newChecked);
        }

    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    return (
        <div style={{ marginTop: '1rem' }}>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                {"Add Personnel"}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Add Personnel 👩‍⚕️👨‍⚕️"}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
          </DialogContentText> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                    >
                        Save
      </Button> */}
                    <List dense className={classes.root}>
                        {checked.map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value}`;
                            return (
                                <ListItem key={value} button>
                                    <ListItemAvatar>
                                        <Chip
                                            variant="outlined"
                                            size="medium"
                                            avatar={<Avatar>{value[0]}</Avatar>}
                                            label={value}
                                            color="primary"

                                            deleteIcon={<DoneIcon />}
                                            style={{ marginRight: '1rem' }}
                                        />
                                    </ListItemAvatar>
                                    {/* <ListItemText id={labelId} primary={` Slot `} /> */}
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            color="primary"
                                            edge="end"
                                            onChange={handleToggle(value)}
                                            checked={checked.indexOf(value) !== -1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                        <ListItem>
                            <FormControl error={formError} className={clsx(classes.margin, classes.textField)}>
                                <InputLabel htmlFor="standard-adornment-person">Personnel Email</InputLabel>
                                <Input

                                    aria-describedby="component-error-text"
                                    id="standard-adornment-person"
                                    value={textValue}
                                    onChange={handleChange('person')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="add"
                                                onClick={handleClick}
                                                onMouseDown={handleMouseDown}
                                            >
                                                <SendIcon></SendIcon>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-error-text">{formError ? "Incorrect Email Format" : ""}</FormHelperText>
                            </FormControl>
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    )
}

WorkerAdder.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    setWorkers: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    api: state.api,
    auth: state.auth,
})

export default connect(mapStateToProps, {
    setWorkers,
})(WorkerAdder)
