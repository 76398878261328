import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { getStoreDetailsbyId, updateWorkers, opencloseShop, updateTimings, updateSlotSize } from './../../actions/APIActions';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
}));
var i = 0;
function RegisterStore(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        console.log(event.target.checked)
        opencloseShop(props.auth.UserAuthData.shopId, event.target.checked)
    };

    const workersOnUpdate = () => {
        var workers = document.getElementById("workerList").value
        workers = workers.split("\n")
        console.log(workers)
        updateWorkers(props.auth.UserAuthData.shopId, workers);
    }

    const timingsOnUpdate = () => {
        var timings = document.getElementById("timingsList").value
        timings = timings.split("\n");
        console.log(timings);
        updateTimings(props.auth.UserAuthData.shopId, timings);
    }

    const slotSizeOnUpdate = () => {
        var slotSize = document.getElementById("slotSize").value;
        console.log(slotSize);
        updateSlotSize(props.auth.UserAuthData.shopId, slotSize);
    }

    if (props.api.selectedStoreDetails === "" && i == 0) {
        props.getStoreDetailsbyId(props.auth.UserAuthData.shopId)
        i++;

        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
    else {

        return (
            <React.Fragment>
                <br></br>
                <Typography className={classes.text} variant="h5">
                    Store Management
                  </Typography>
                <br></br>
                <TextField id="outlined-basic" variant="outlined" label="Store Name" disabled value={props.api.selectedStoreDetails.displayName + ""}>
                </TextField>
                <br></br>

                {/* <TextField id="outlined-basic1" variant="outlined" defaultValue={props.api.selectedStoreDetails.slotSize !== undefined ? props.api.selectedStoreDetails.slotSize : ""} InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={workersOnUpdate}
                                edge="end"
                            >
                                <SaveIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}>
                </TextField> */}
                <TextField
                    id="slotSize"
                    multiline
                    //label="Slot Size"
                    rows="1"
                    variant="outlined"
                    helperText="Change your Slot Size (Refresh after Saving)"
                    defaultValue={props.api.selectedStoreDetails.slotSize !== undefined ? props.api.selectedStoreDetails.slotSize : ""}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="Save"
                                onClick={slotSizeOnUpdate}
                                edge="end"
                            >
                                <SaveIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                >

                </TextField>
                <br>
                </br>
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Close</Grid>
                        <Grid item>
                            <Switch
                                defaultChecked={props.api.selectedStoreDetails.openCheck === true ? true : false}
                                onChange={handleChange}
                                color="primary"
                                name="shopCheck"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                        <Grid item>Open</Grid>
                    </Grid>
                </Typography>
                <br>
                </br>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows="4"
                    variant="outlined"
                    helperText="Be descriptive, include landmarks etc"
                    defaultValue={props.api.selectedStoreDetails.address}
                >

                </TextField>
                <br></br>
                <TextField
                    style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
                    id="workerList"
                    multiline
                    rows="4"
                    defaultValue=""
                    variant="outlined"
                    helperText="Add or Remove Employees by email (Add each in new line, and remove line to delete)"
                    defaultValue={props.api.selectedStoreDetails.workerIds !== undefined
                        ? props.api.selectedStoreDetails.workerIds.join("\n")
                        : ""
                    }
                    //onChange={handleWorkerChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={workersOnUpdate}
                                edge="end"
                            >
                                <SaveIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}

                >

                </TextField>
                {/* <br></br>
                <TextField
                    style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
                    id="timingsList"
                    multiline
                    rows="4"
                    defaultValue=""
                    variant="outlined"
                    helperText="Modify your Slot timings (Hourwise between 5AM and 11PM)(Add each in new line, and remove line to delete)"
                    defaultValue={props.api.selectedStoreDetails.slotTimings !== undefined
                        ? props.api.selectedStoreDetails.slotTimings.join("\n")
                        : ""
                    }
                    //onChange={handleWorkerChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={timingsOnUpdate}
                                edge="end"
                            >
                                <SaveIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}

                >

                </TextField> */}
                <br></br>
                {/* <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <br></br> */}
            </React.Fragment>
        )
    }
}
RegisterStore.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getStoreDetailsbyId: PropTypes.func.isRequired,
    updateWorkers: PropTypes.func.isRequired,
    opencloseShop: PropTypes.func.isRequired, 
    updateTimings: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    api: state.api,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getStoreDetailsbyId, updateWorkers, opencloseShop, updateTimings

})(RegisterStore);
