import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';

import SlotDrawer from './slotDrawer';
import UnitTimings from './unitTimings';
import WorkerAdder from './workerAdder';

import {
    editUnit,
} from './../../actions/APIActions';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 420,
        textAlign: 'left',
        margin: 'auto',
        marginBottom: "1rem",
        marginLeft: "1rem",
        marginRight: "1rem"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
    },
    textfield: {
        marginBottom: '1rem'
    },
    timings: {
        marginBottom: '1rem'
    }
}));

const UnitDisplay = (props) => {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);
    const [description, setDescription] = React.useState(props.displayDescription)
    const [picture, setPicture] = React.useState(props.displayPicture)
    const [address, setAddress] = React.useState(props.address)
    const [slotSize, setSlotSize] = React.useState(props.slotSize)

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const update = () => {

    }

    const descriptionOnUpdate = (event) => {
        console.log("Value: ", event.target.value);
        setDescription(event.target.value);
    }

    const updateHospitalDescription = () => {
        props.editUnit(
            props.hospitalId,
            props.stateName,
            props.cityName,
            props.areaName,
            props.locationName,
            props.hospitalName,
            props.hospitalUnitName,
            props.hospitalUnitCategoryName,
            {
                displayDescription: description
            }
        );
    }

    const pictureOnUpdate = (event) => {
        console.log("Value: ", event.target.value);
        setPicture(event.target.value);
    }

    const updateHospitalPicture = () => {
        props.editUnit(
            props.hospitalId,
            props.stateName,
            props.cityName,
            props.areaName,
            props.locationName,
            props.hospitalName,
            props.hospitalUnitName,
            props.hospitalUnitCategoryName,
            {
                displayPicture: picture
            }
        );
    }

    const addressOnUpdate = (event) => {
        console.log("Value: ", event.target.value);
        setAddress(event.target.value);
    }

    const updateHospitalAddress = () => {
        props.editUnit(
            props.hospitalId,
            props.stateName,
            props.cityName,
            props.areaName,
            props.locationName,
            props.hospitalName,
            props.hospitalUnitName,
            props.hospitalUnitCategoryName,
            {
                address: address
            }
        );
    }

    const slotSizeOnUpdate = (event) => {
        console.log("Value: ", event.target.value);
        setSlotSize(event.target.value);
    }

    const updateSlotSize = () => {
        props.editUnit(
            props.hospitalId,
            props.stateName,
            props.cityName,
            props.areaName,
            props.locationName,
            props.hospitalName,
            props.hospitalUnitName,
            props.hospitalUnitCategoryName,
            {
                slotSize: slotSize
            }
        );
    }

    return (
        <React.Fragment >
            {/* <Typography className={classes.text} variant="h5" align="center" style={{ marginTop: "1rem", marginBottom: "1rem" }}>Your Appointment</Typography> */}

            <Card className={classes.root}
            // variant={"outlined"}
            >
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {props.hospitalName[0]}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={props.hospitalName}
                    subheader={props.hospitalUnitCategoryName + " ▶ " + props.hospitalUnitName}
                />
                <CardMedia
                    className={classes.media}
                    image={props.displayPicture}
                    title={props.hospitalUnitName}
                />
                <CardContent>
                </CardContent>
                <CardActions disableSpacing>
                    {/* <IconButton aria-label="add to favorites">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareIcon />
                            </IconButton> */}
                    <Typography align="center" style={{ paddingLeft: "1rem" }}>
                        Details
                            </Typography>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <TextField id="hosdesc"
                            className={classes.textfield}
                            multiline
                            onChange={descriptionOnUpdate}
                            variant="outlined" label="Hospital Description"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={props.displayDescription}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Save"
                                        onClick={updateHospitalDescription}
                                        edge="end"
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        >
                        </TextField>
                        <TextField id="hospic"
                            className={classes.textfield}
                            multiline
                            onChange={pictureOnUpdate}
                            variant="outlined" label="Hospital Picture URL"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={props.displayPicture}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Save"
                                        onClick={updateHospitalPicture}
                                        edge="end"
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        >
                        </TextField>
                        <TextField id="hosadd"
                            className={classes.textfield}
                            multiline
                            onChange={addressOnUpdate}
                            variant="outlined" label="Hospital Address"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={props.address}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Save"
                                        onClick={updateHospitalAddress}
                                        edge="end"
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        >
                        </TextField>
                        <TextField id="hossize"
                            className={classes.textfield}
                            variant="outlined" label="Slot Size"
                            onChange={slotSizeOnUpdate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={props.slotSize}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Save"
                                        onClick={updateSlotSize}
                                        edge="end"
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        >
                        </TextField>
                        <Divider variant="middle" />
                        <Typography variant="h5"
                            style={{ marginTop: '1rem' }}
                            gutterBottom>
                            Edit Timings
                        </Typography>
                        <UnitTimings
                            className={classes.timings}
                            stateName={props.stateName}
                            cityName={props.cityName}
                            areaName={props.areaName}
                            locationName={props.locationName}
                            hospitalId={props.hospitalId}
                            hospitalName={props.hospitalName}
                            hospitalUnitName={props.hospitalUnitName}
                            hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                            timings={props.timings.Monday.hours}
                            weekday="Monday"
                            openCheck={props.timings.Monday.open}
                        ></UnitTimings>
                        <UnitTimings
                            className={classes.timings}
                            stateName={props.stateName}
                            cityName={props.cityName}
                            areaName={props.areaName}
                            locationName={props.locationName}
                            hospitalId={props.hospitalId}
                            hospitalName={props.hospitalName}
                            hospitalUnitName={props.hospitalUnitName}
                            hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                            timings={props.timings.Tuesday.hours}
                            weekday="Tuesday"
                            openCheck={props.timings.Tuesday.open}
                        ></UnitTimings>
                        <UnitTimings
                            className={classes.timings}
                            stateName={props.stateName}
                            cityName={props.cityName}
                            areaName={props.areaName}
                            locationName={props.locationName}
                            hospitalId={props.hospitalId}
                            hospitalName={props.hospitalName}
                            hospitalUnitName={props.hospitalUnitName}
                            hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                            timings={props.timings.Wednesday.hours}
                            weekday="Wednesday"
                            openCheck={props.timings.Wednesday.open}
                        ></UnitTimings>
                        <UnitTimings
                            className={classes.timings}
                            stateName={props.stateName}
                            cityName={props.cityName}
                            areaName={props.areaName}
                            locationName={props.locationName}
                            hospitalId={props.hospitalId}
                            hospitalName={props.hospitalName}
                            hospitalUnitName={props.hospitalUnitName}
                            hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                            timings={props.timings.Thursday.hours}
                            weekday="Thursday"
                            openCheck={props.timings.Thursday.open}
                        ></UnitTimings>
                        <UnitTimings
                            className={classes.timings}
                            stateName={props.stateName}
                            cityName={props.cityName}
                            areaName={props.areaName}
                            locationName={props.locationName}
                            hospitalId={props.hospitalId}
                            hospitalName={props.hospitalName}
                            hospitalUnitName={props.hospitalUnitName}
                            hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                            timings={props.timings.Friday.hours}
                            weekday="Friday"
                            openCheck={props.timings.Friday.open}
                        ></UnitTimings>
                        <UnitTimings
                            className={classes.timings}
                            stateName={props.stateName}
                            cityName={props.cityName}
                            areaName={props.areaName}
                            locationName={props.locationName}
                            hospitalId={props.hospitalId}
                            hospitalName={props.hospitalName}
                            hospitalUnitName={props.hospitalUnitName}
                            hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                            timings={props.timings.Saturday.hours}
                            weekday="Saturday"
                            openCheck={props.timings.Saturday.open}
                        ></UnitTimings>
                        <UnitTimings
                            className={classes.timings}
                            stateName={props.stateName}
                            cityName={props.cityName}
                            areaName={props.areaName}
                            locationName={props.locationName}
                            hospitalId={props.hospitalId}
                            hospitalName={props.hospitalName}
                            hospitalUnitName={props.hospitalUnitName}
                            hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                            timings={props.timings.Sunday.hours}
                            weekday="Sunday"
                            openCheck={props.timings.Sunday.open}
                        ></UnitTimings>
                        <Divider
                            style={{ marginTop: '1rem' }}
                            variant="middle" />
                        <Typography variant="h5"
                            style={{ marginTop: '1rem' }}
                            gutterBottom>
                            Edit Authorized Personnel
                        </Typography>
                        <WorkerAdder
                            className={classes.timings}
                            stateName={props.stateName}
                            cityName={props.cityName}
                            areaName={props.areaName}
                            locationName={props.locationName}
                            hospitalId={props.hospitalId}
                            hospitalName={props.hospitalName}
                            hospitalUnitName={props.hospitalUnitName}
                            hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                            workerIds={props.workerIds}
                            weekday="Friday"></WorkerAdder>
                    </CardContent>
                </Collapse>
                <CardActions>
                    <Button size="small" color="primary" variant="outlined"
                        onClick={handleExpandClick}
                    >
                        Edit Unit Details
                    </Button>
                    <SlotDrawer
                        text={"Get Slots List"}
                        hospitalId={props.hospitalId}
                        hospitalName={props.hospitalName}
                        hospitalUnitName={props.hospitalUnitName}
                        hospitalUnitCategoryName={props.hospitalUnitCategoryName}
                    ></SlotDrawer>
                </CardActions>
            </Card>
        </React.Fragment >
    )
}

UnitDisplay.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    editUnit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    api: state.api,
    auth: state.auth,
})


export default connect(mapStateToProps, {
    editUnit,
})(UnitDisplay)
