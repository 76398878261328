export const SAVEAUTHINFO = 'SAVEAUTHINFO';
export const GETSTATELIST = 'GETSTATELIST';
export const SETSTATE = 'SETSTATE';
export const GETCITYLIST = 'GETCITYLIST';
export const SETCITY = 'SETCITY';
export const GETAREALIST = 'GETAREALIST';
export const SETAREA = 'SETAREA';
export const GETLOCATIONLIST = 'GETLOCATIONLIST';
export const SETLOCATION = 'SETLOCATION';
export const GETSTORELIST = 'GETSTORELIST'
export const SETSTORE = 'SETSTORE';
export const GETSTOREDETAILS = 'GETSTOREDETAILS';
export const GETSTOREDETAILSBYID = 'GETSTOREDETAILSBYID';
export const SETTICKET = 'SETTICKET';
export const SETRESERVELOCK = 'SETRESERVELOCK';
export const SETUNBANTIME = 'SETUNBANTIME';
export const SETTIMINGS = 'SETTIMINGS';

export const GETSTATELIST_MED = 'GETSTATELIST_MED';
export const SETSTATE_MED = 'SETSTATE_MED';
export const GETCITYLIST_MED = 'GETCITYLIST_MED';
export const SETCITY_MED = 'SETCITY_MED';
export const GETAREALIST_MED = 'GETAREALIST_MED';
export const SETAREA_MED = 'SETAREA_MED';
export const GETLOCATIONLIST_MED = 'GETLOCATIONLIST_MED';
export const SETLOCATION_MED = 'SETLOCATION_MED';
export const GETHOSPITALLIST = 'GETHOSPITALLIST';
export const SETHOSPITAL = 'SETHOSPITAL';
export const GETHOSPITALUNITSLIST = 'GETHOSPITALUNITSLIST';
export const SETHOSPITALUNIT = 'SETHOSPITALUNIT';
export const GETHOSPITALDETAILS = 'GETHOSPITALDETAILS';
export const GETHOSPITALDETAILSBYID = 'GETHOSPITALDETAILSBYID';
export const GETTESTTYPE = 'GETTESTTYPE';
export const SETTESTTYPE = 'SETTESTTYPE';
export const GETTESTS = 'GETTESTS';
export const SETTEST = 'SETTEST';
export const SETTICKET_MED = 'SETTICKET_MED';
export const SETTIMINGS_MED = 'SETTIMINGS_MED';
export const SETFAVORITES = 'SETFAVORITES';
export const SETTIME_MED = 'SETTIME_MED';
export const SETDATE_MED = 'SETDATE_MED';
export const SETDEPENDENCY_MED = 'SETDEPENDENCY_MED';
export const SETGENDER_MED = 'SETGENDER_MED';
export const SETNAME_MED = 'SETNAME_MED';
export const SETPHNO_MED = 'SETPHNO_MED';

export const SETTIMINGS_ADMIN = 'SETTIMINGS_ADMIN';
export const SETWORKERS = 'SETWORKERS';
export const SETADDERCONTENT = 'SETADDERCONTENT';
///ERROR///
export const SETERROR = 'SETERROR';
export const SETERRORMESSAGE = 'SETERRORMESSAGE'