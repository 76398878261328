import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save'

import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

import {
    editUnit,
} from './../../actions/APIActions';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
}));


export const UnitTimings = (props) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const timings = [
        "1AM",
        "2AM",
        "3AM",
        "4AM",
        "5AM",
        "6AM",
        "7AM",
        "8AM",
        "9AM",
        "10AM",
        "11AM",
        "12PM",
        "1PM",
        "2PM",
        "3PM",
        "4PM",
        "5PM",
        "6PM",
        "7PM",
        "8PM",
        "9PM",
        "10PM",
        "11PM",
        "12AM",

    ]
    const [checked, setChecked] = React.useState(props.timings);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        console.log(newChecked);
    };

    const [OpenCheck, setOpenChecked] = React.useState(props.openCheck);

    const handleChange = (event) => {
        setOpenChecked(event.target.checked);
    };

    const saveTimings = () => {
        console.log("Saving Timings: ", {
            [props.weekday]: { hours: checked, openCheck: OpenCheck },
        })
        props.editUnit(
            props.hospitalId,
            props.stateName,
            props.cityName,
            props.areaName,
            props.locationName,
            props.hospitalName,
            props.hospitalUnitName,
            props.hospitalUnitCategoryName,
            {
                [props.weekday]: { hours: checked, openCheck: OpenCheck },
            }
        )
    }

    console.log("Emails: ", props.timings)

    return (
        <div style={{ marginTop: '1rem' }}>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                {"Timings for " + props.weekday}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.weekday + " Timings 🗓"}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
          </DialogContentText> */}
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Open/Close Unit</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={OpenCheck} onChange={handleChange} name="openCheck" />}
                                label="Unit Open ?"
                            />
                        </FormGroup>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={saveTimings}
                    >
                        Save
      </Button>
                    <List dense className={classes.root}>
                        {timings.map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value}`;
                            return (
                                <ListItem key={value} button>
                                    <ListItemAvatar>
                                        <Chip
                                            variant="outlined"
                                            size="medium"
                                            avatar={<Avatar>{String(value).replace(/(AM|PM)/g, "")}</Avatar>}
                                            label={String(value).replace(/[0-9]/g, '')}
                                            color="primary"

                                            deleteIcon={<DoneIcon />}
                                            style={{ marginRight: '1rem' }}
                                        />
                                    </ListItemAvatar>
                                    {/* <ListItemText id={labelId} primary={` Slot `} /> */}
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            color="primary"
                                            edge="end"
                                            onChange={handleToggle(value)}
                                            checked={checked.indexOf(value) !== -1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    )
}

UnitTimings.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    editUnit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    api: state.api,
    auth: state.auth,
})


export default connect(mapStateToProps, {
    editUnit,
})(UnitTimings)
