import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from './../logo.svg';
import ProfileForm from './../components/medical/customer/ProfileForm';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    root: {
    },
    div: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto'
    }
});

function LoadingPage(props) {
    const classes = useStyles();

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        function tick() {
            // reset when reaching 100%
            setProgress((oldProgress) => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                {/* <Typography className={classes.text} variant="h1">
                    Braggi
              </Typography>
                <Typography className={classes.text} variant="h6">
                    Shop Safely during Lockdowns
              </Typography> */}
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                {/* <MainChooser></MainChooser> */}
                <ProfileForm></ProfileForm>
            </header>
            {/* <SlotsDisplay></SlotsDisplay> */}
            {/* <TicketDisplay></TicketDisplay> */}
        </div>
    )
}
LoadingPage.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error,
});

export default connect(mapStateToProps, {

})(LoadingPage);
