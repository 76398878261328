import React from 'react';
import { withStyles, makeStyles, darken, lighten } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Icon from '@material-ui/core/Icon';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import purple from '@material-ui/core/colors/purple';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'react-redux-firebase';
import clsx from 'clsx';
import {
  api_reserveSlot
} from '../../actions/APIActions';
import { SLOTTIMES } from './../../settings/slots';
var moment = require('moment');

var slotTimes = SLOTTIMES

const open = true;
const messages = [
  {
    time: "10AM",
    slotSize: 25,
    slotsAvailable: 6,
    person: '/static/images/avatar/5.jpg',
  },
  {
    time: "11AM",
    slotSize: 25,
    slotsAvailable: 5,
    person: '/static/images/avatar/1.jpg',
  },
  {
    time: "12PM",
    slotSize: 25,
    slotsAvailable: 14,
    person: '/static/images/avatar/2.jpg',
  },
  {
    time: "3PM",
    slotSize: 25,
    slotsAvailable: 12,
    person: '/static/images/avatar/3.jpg',
  },
  {
    time: "4PM",
    slotSize: 25,
    slotsAvailable: 15,
    person: '/static/images/avatar/4.jpg',
  },
  {
    time: "5PM",
    slotSize: 25,
    slotsAvailable: 1,
    person: '/static/images/avatar/5.jpg',
  },
  {
    time: "6PM",
    slotSize: 25,
    slotsAvailable: 25,
    person: '/static/images/avatar/1.jpg',
  },
];

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  const StyledTableRowforTotal = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 700,
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
//   }
  
//   const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
//   ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'total', numeric: true, disablePadding: false, label: 'Total Visited' },
    { id: '5AM', numeric: true, disablePadding: true, label: '5AM Visited' },
    { id: '6AM', numeric: true, disablePadding: true, label: '6AM Visited' },
    { id: '7AM', numeric: true, disablePadding: true, label: '7AM Visited' },
    { id: '8AM', numeric: true, disablePadding: true, label: '8AM Visited' },
    { id: '9AM', numeric: true, disablePadding: true, label: '9AM Visited' },
    { id: '10AM', numeric: true, disablePadding: true, label: '10AM Visited' },
    { id: '11AM', numeric: true, disablePadding: true, label: '11AM Visited' },
    { id: '12PM', numeric: true, disablePadding: true, label: '12PM Visited' },
    { id: '1PM', numeric: true, disablePadding: true, label: '1PM Visited' },
    { id: '2PM', numeric: true, disablePadding: true, label: '2PM Visited' },
    { id: '3PM', numeric: true, disablePadding: true, label: '3PM Visited' },
    { id: '4PM', numeric: true, disablePadding: true, label: '4PM Visited' },
    { id: '5PM', numeric: true, disablePadding: true, label: '5PM Visited' },
    { id: '6PM', numeric: true, disablePadding: true, label: '6PM Visited' },
    { id: '7PM', numeric: true, disablePadding: true, label: '7PM Visited' },
    { id: '8PM', numeric: true, disablePadding: true, label: '8PM Visited' },
    { id: '9PM', numeric: true, disablePadding: true, label: '9PM Visited' },
    { id: '10PM', numeric: true, disablePadding: true, label: '10PM Visited' },
    { id: '11PM', numeric: true, disablePadding: true, label: '11PM Visited' },
  ];

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <StyledTableRow>
          <StyledTableCell >
            {/* <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            /> */}
          </StyledTableCell>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(purple[500]),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: darken(purple[500]),
          },
    title: {
      flex: '1 1 100%',
    },
  }));
  
  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Nutrition
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  
function EnhancedTable(props) {
    const rows = props.storeList;
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
  
    const isSelected = (name) => selected.indexOf(name) !== -1;
  
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
              <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell component="th" id={"total"} scope="row" padding="none"><b>Total</b></StyledTableCell>
                    <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot5AM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot6AM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot7AM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot8AM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot9AM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot10AM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot12PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot1PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot2PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot3PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot4PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot5PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot6PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot7PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot8PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot9PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot10PM.slotsReservedby.filter(value => value.visited === true).length+
                    row.slots.slot11PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot5AM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot6AM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot7AM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot8AM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot9AM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot10AM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot11AM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot12PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot1PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot2PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot3PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot4PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot5PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot6PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot7PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot8PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot9PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot10PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                <StyledTableCell align="right">{
                  rows.reduce(function(sum, row){
                    return sum = sum+row.slots.slot11PM.slotsReservedby.filter(value => value.visited === true).length
                  }, 0)
                }</StyledTableCell>
                  
                  </StyledTableRow>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                      {console.log(row)}
                    const isItemSelected = isSelected(row.displayName);
                    const labelId = `enhanced-table-checkbox-${index}`;
  
                    return (
                      <StyledTableRow
                        hover
                        //onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.displayName}
                        selected={isItemSelected}
                      >
                        <StyledTableCell >
                          {/* <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> */}
                        </StyledTableCell>
                        <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                          {row.displayName}
                        </StyledTableCell>
                        <StyledTableCell align="right">{
                        row.slots.slot5AM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot6AM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot7AM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot8AM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot9AM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot10AM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot12PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot1PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot2PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot3PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot4PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot5PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot6PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot7PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot8PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot9PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot10PM.slotsReservedby.filter(value => value.visited === true).length+
                        row.slots.slot11PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot5AM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot6AM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot7AM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot8AM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot9AM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot10AM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot11AM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot12PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot1PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot2PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot3PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot4PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot5PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot6PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot7PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot8PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot9PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot10PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        <StyledTableCell align="right">{row.slots.slot11PM.slotsReservedby.filter(value => value.visited === true).length}</StyledTableCell>
                        

                      </StyledTableRow>
                    );
                  })}                
                {emptyRows > 0 && (
                  <StyledTableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <StyledTableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </div>
    );
  }

  const reducer = (accumulator, currentValue) => accumulator + currentValue;

function Dashboard(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  if (isEmpty(props.api.storeList)) {
    return (
      <React.Fragment>

      </React.Fragment>
    )
  }

  else {
    return (
      <React.Fragment>
        <CssBaseline />
        <Paper square className={classes.paper} >
        {/* <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Dessert (100g serving)</StyledTableCell>
            <StyledTableCell align="right">Calories</StyledTableCell>
            <StyledTableCell align="right">Fat&nbsp;(g)</StyledTableCell>
            <StyledTableCell align="right">Carbs&nbsp;(g)</StyledTableCell>
            <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
    <EnhancedTable storeList={props.api.storeList}></EnhancedTable>
        </Paper>
      </React.Fragment>
    );
  }
}

function reserveSlot(props, time){
  console.log("Reserving for " + time)
  props.api_reserveSlot(props.api.selectedState.selectedState, props.api.selectedCity.selectedCity, props.api.selectedArea.selectedArea, props.api.selectedLocation.selectedLocation, props.api.selectedStore.selectedStore, time, props.auth.UserAuthData.email)
}


Dashboard.propTypes = {
  api: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  api: state.api,
  auth: state.auth,
});

export default connect(mapStateToProps, {

})(Dashboard);