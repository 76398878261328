import { combineReducers } from 'redux';
import authReducer from './authReducer';
import apiReducer from './apiReducer';
import errorReducer from './errorReducer';
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'

export default combineReducers({
  api: apiReducer,
  auth: authReducer,
  error: errorReducer,
  routing: routerReducer,
});