import {
    GETSTATELIST,
    SETSTATE,
    GETCITYLIST,
    SETCITY,
    GETAREALIST,
    SETAREA,
    GETLOCATIONLIST,
    SETLOCATION,
    GETSTORELIST,
    SETSTORE,
    GETSTOREDETAILS,
    SETTIMINGS,

    GETSTATELIST_MED,
    SETSTATE_MED,
    GETCITYLIST_MED,
    SETCITY_MED,
    GETAREALIST_MED,
    SETAREA_MED,
    GETLOCATIONLIST_MED,
    SETLOCATION_MED,
    GETHOSPITALLIST,
    SETHOSPITAL,
    GETHOSPITALUNITSLIST,
    SETHOSPITALUNIT,
    GETHOSPITALDETAILS,
    GETHOSPITALDETAILSBYID,
    GETTESTTYPE,
    SETTESTTYPE,
    GETTESTS,
    SETTEST,
    SETTICKET_MED,
    SETTIMINGS_MED,
    SETFAVORITES,
    SETTIME_MED,
    SETDATE_MED,
    SETDEPENDENCY_MED,
    SETGENDER_MED,
    SETNAME_MED,
    SETPHNO_MED,

    SETTIMINGS_ADMIN,
    SETWORKERS,
    SETADDERCONTENT,
}
    from './../actions/types';

let todayDate = new Date();

const initialState = {
    stateList: [],
    selectedState: "",
    cityList: [],
    selectedCity: "",
    areaList: [],
    selectedArea: "",
    locationList: [],
    selectedLocation: "",
    storeList: [],
    selectedStore: "",
    selectedStoreDetails: "",

    stateListMED: [],
    selectedStateMED: "",
    cityListMED: [],
    selectedCityMED: "",
    areaListMED: [],
    selectedAreaMED: "",
    locationListMED: [],
    selectedLocationMED: "",
    hospitalList: [],
    selectedHospital: "",
    hospitalUnitList: [],
    selectedHospitalUnit: "",
    selectedHospitalDetails: "",
    testTypes: [],
    selectedTestType: "",
    tests: [],
    selectedTest: "",
    favorites: [],
    //timeMED: "",
    timeMED: { timeMED: String(String(todayDate.getHours() % 12 || 12) + String((todayDate.getHours() < 12 || todayDate.getHours() === 24) ? "AM" : "PM")) },
    //dateMED: "",
    dateMED: { dateMED: String(String(todayDate.getMonth() + 1) + "-" + todayDate.getDate() + "-" + todayDate.getFullYear()) },
    dependencyMED: "SELF",
    genderMED: "MALE",
    nameMED: "",
    phNoMED: "",
    timings: {
        Monday: {
            open: false,
            hours: []
        },
        Tuesday: {
            open: false,
            hours: []
        },
        Wednesday: {
            open: false,
            hours: []
        },
        Thursday: {
            open: false,
            hours: []
        },
        Friday: {
            open: false,
            hours: []
        },
        Saturday: {
            open: false,
            hours: []
        },
        Sunday: {
            open: false,
            hours: []
        }
    },
    workers: [],
    adderContent: {
        hospitalUnitName: "",
        hospitalUnitCategoryName: "",
        displayDescription: "",
        displayPicture: "",
        address: "",
        slotSize: 25,
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GETSTATELIST:
            {
                return {
                    ...state,
                    stateList: action.payload
                };
            }
        case SETSTATE:
            {
                return {
                    ...state,
                    selectedState: action.payload
                };
            }
        case GETCITYLIST:
            {
                return {
                    ...state,
                    cityList: action.payload
                };
            }

        case SETCITY:
            {
                return {
                    ...state,
                    selectedCity: action.payload
                };
            }

        case GETAREALIST:
            {
                return {
                    ...state,
                    areaList: action.payload
                };
            }

        case SETAREA:
            {
                return {
                    ...state,
                    selectedArea: action.payload
                };
            }

        case GETLOCATIONLIST:
            {
                return {
                    ...state,
                    locationList: action.payload
                };
            }

        case SETLOCATION:
            {
                return {
                    ...state,
                    selectedLocation: action.payload
                };
            }

        case GETSTORELIST:
            {
                return {
                    ...state,
                    storeList: action.payload
                };
            }

        case SETSTORE:
            {
                return {
                    ...state,
                    selectedStore: action.payload
                };
            }

        case GETSTOREDETAILS:
            {
                return {
                    ...state,
                    selectedStoreDetails: action.payload
                };
            }

        case SETTIMINGS:
            {
                return {
                    ...state,
                    "selectedStoreDetails.slotTimings": action.payload
                };
            }

        //////MED//////

        case GETSTATELIST_MED:
            {
                return {
                    ...state,
                    stateListMED: action.payload
                };
            }
        case SETSTATE_MED:
            {
                return {
                    ...state,
                    selectedStateMED: action.payload
                };
            }
        case GETCITYLIST_MED:
            {
                return {
                    ...state,
                    cityListMED: action.payload
                };
            }

        case SETCITY_MED:
            {
                return {
                    ...state,
                    selectedCityMED: action.payload
                };
            }

        case GETAREALIST_MED:
            {
                return {
                    ...state,
                    areaListMED: action.payload
                };
            }

        case SETAREA_MED:
            {
                return {
                    ...state,
                    selectedAreaMED: action.payload
                };
            }

        case GETLOCATIONLIST_MED:
            {
                return {
                    ...state,
                    locationListMED: action.payload
                };
            }

        case SETLOCATION_MED:
            {
                return {
                    ...state,
                    selectedLocationMED: action.payload
                };
            }

        case GETHOSPITALLIST:
            {
                return {
                    ...state,
                    hospitalList: action.payload
                };
            }

        case SETHOSPITAL:
            {
                return {
                    ...state,
                    selectedHospital: action.payload
                };
            }


        case GETHOSPITALUNITSLIST:
            {
                return {
                    ...state,
                    hospitalUnitList: action.payload
                };
            }

        case SETHOSPITALUNIT:
            {
                return {
                    ...state,
                    selectedHospitalUnit: action.payload
                };
            }


        case GETTESTTYPE:
            {
                return {
                    ...state,
                    testTypes: action.payload
                };
            }

        case SETTESTTYPE:
            {
                return {
                    ...state,
                    selectedTestType: action.payload
                };
            }


        case GETTESTS:
            {
                return {
                    ...state,
                    tests: action.payload
                };
            }

        case SETTEST:
            {
                return {
                    ...state,
                    selectedTest: action.payload
                };
            }

        case GETHOSPITALDETAILS:
            {
                return {
                    ...state,
                    selectedHospitalDetails: action.payload
                };
            }

        case SETTIMINGS_MED:
            {
                return {
                    ...state,
                    "selectedStoreDetails.slotTimings": action.payload
                };
            }

        case SETFAVORITES:
            {
                return {
                    ...state,
                    favorites: action.payload
                };
            }

        case SETTIME_MED:
            {
                return {
                    ...state,
                    timeMED: action.payload
                };
            }

        case SETDATE_MED:
            {
                return {
                    ...state,
                    dateMED: action.payload
                };
            }

        case SETDEPENDENCY_MED:
            {
                return {
                    ...state,
                    dependencyMED: action.payload
                };
            }

        case SETGENDER_MED:
            {
                return {
                    ...state,
                    genderMED: action.payload
                };
            }

        case SETNAME_MED:
            {
                return {
                    ...state,
                    nameMED: action.payload
                };
            }

        case SETPHNO_MED:
            {
                return {
                    ...state,
                    phNoMED: action.payload
                };
            }

        case SETTIMINGS_ADMIN:
            {
                return {
                    ...state,
                    timings: action.payload
                };
            }

        case SETWORKERS:
            {
                return {
                    ...state,
                    workers: action.payload
                };
            }

        case SETADDERCONTENT:
            {
                return {
                    ...state,
                    adderContent: action.payload
                };
            }


        default:
            {
                return state
            }

    }
}