import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import down from './../../../down.svg'
import FavoriteIcon from '@material-ui/icons/Favorite';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import { blue } from '@material-ui/core/colors';

import {
    getStateList_MED,
    setStateName_MED,
    getCityList_MED,
    setCityName_MED,
    getAreaList_MED,
    setAreaName_MED,
    getLocationList_MED,
    setLocationName_MED,
    getHospitalList_MED,
    getTestsList_MED,
    setHospitalName_MED,
    setStoreName,
    getStoreDetails,
    addFavorites_MED,
    setDependency_MED,
    setGender_MED,
    setName_MED,
    setPhNo_MED,
} from '../../../actions/APIActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'react-redux-firebase';

var moment = require('moment');

var i = 0
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const ColoredRadio = withStyles({
    root: {
      color: blue[400],
      '&$checked': {
        color: blue[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

function MainChooser(props) {
    const classes = useStyles();
    const [favorite, setFavorite] = React.useState('');
    const [state, setState] = React.useState('');
    const [city, setCity] = React.useState('');
    const [area, setArea] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [store, setStore] = React.useState('');
    const [depValue, setDepValue] = React.useState('SELF');
    const [gender, setGender] = React.useState("MALE");
    const [name, setName] = React.useState("");
    const [phNo, setPhNo] = React.useState("");

    const handleDependencyChange = (event) => {
        setDepValue(event.target.value);
        props.setDependency_MED(event.target.value);
    };

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        props.setGender_MED(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
        props.setName_MED(event.target.value);
    };

    const handlePhNoChange = (event) => {
        setPhNo(event.target.value);
        props.setPhNo_MED(event.target.value);
    };

    const handleStateChange = event => {
        setState(event.target.value);
        props.setStateName_MED(event.target.value);
        props.getCityList_MED(event.target.value);
    };
    const handleCityChange = event => {
        setCity(event.target.value);
        props.setCityName_MED(event.target.value);
        props.getAreaList_MED(state, event.target.value);
    };
    const handleAreaChange = event => {
        setArea(event.target.value);
        props.setAreaName_MED(event.target.value);
        props.getLocationList_MED(state, city, event.target.value);
        props.getHospitalList_MED(state, city, event.target.value, "");
    };
    const handleLocationChange = event => {
        setLocation(event.target.value);
        props.setLocationName_MED(event.target.value);
        props.getHospitalList_MED(state, city, area, event.target.value);
    };
    const handleStoreChange = event => {
        setStore(event.target.value);
        props.setHospitalName_MED(event.target.value)
        props.getTestsList_MED(state, city, area, location, event.target.value);
    };

    const handleFavoriteChange = event => {
        console.log(event.target.value, state, city, area, location, store)
        setFavorite(event.target.value);
        console.log(event.target.value);
        props.auth.UserAuthData.bookmarks.map(({ stateName, cityName, areaName, locationName, hospitalName }) => {
            console.log("Name: ", stateName, cityName, areaName, locationName, hospitalName)
            if (hospitalName === event.target.value) {
                console.log("Setting")
                console.log("Values: ", stateName, cityName, areaName, locationName, hospitalName, event.target.value)
                setState(stateName);
                props.setStateName_MED(stateName);
                setCity(cityName);
                props.setCityName_MED(cityName);
                setArea(areaName);
                props.setAreaName_MED(areaName);
                setLocation(locationName);
                props.setLocationName_MED(locationName);
                setStore(hospitalName);
                props.setHospitalName_MED(hospitalName);
                props.getTestsList_MED(stateName, cityName, areaName, locationName, hospitalName);
            }
        })
    };

    const handleAddFavorite = event => {
        if (state !== '' && city !== '' && area !== '' && store !== '') {
            console.log(props.auth.UserAuthData.email, state, city, area, location, store, props.auth.UserAuthData.bookmarks)
            props.addFavorites_MED(props.auth.UserAuthData.email, state, city, area, location, store)
        }
        else {
            console.log("NULL")
        }
    };

    // if (isEmpty(props.editor.botList) === true){
    //     console.log("Empty")
    //     props.getBotlist()
    // }
    //TODO:check lists empty and send request
    if (isEmpty(props.api.stateListMED) === true && i == 0) {
        console.log("State List is Empty");
        props.getStateList_MED();
        i++;
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }

    else {

        return (
            <div style={{ marginBottom: '7rem' }}>
                <Typography className={classes.text} variant="h4" gutterBottom>
                    {
                        props.auth.unbanTime !== "" && props.auth.reserveLock !== false
                            ?
                            moment(props.auth.UserAuthData.activity.slot, "HHa").isSameOrAfter(moment()) === false ?
                                (<p>
                                    Hope you had a safe and peaceful experience today <br /> (You can book again tomorrow)
                                </p>)
                                :
                                (<p>
                                    Your Slot has been Booked!<br /> View your booking from the menu down below 👇
                                </p>)
                            : "You can a book a slot for today down below 👇"
                    }
                </Typography>
                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select from Favorites</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="StateSelect"
                        value={favorite}
                        onChange={handleFavoriteChange}
                        label="Location"
                    >
                        {
                            props.auth.UserAuthData.bookmarks.map(({ hospitalName, type }) => (
                                <MenuItem value={hospitalName} key={hospitalName}>
                                    {type === "MED" ? hospitalName : null}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select State</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="StateSelect"
                        value={state}
                        onChange={handleStateChange}
                        label="Location"
                    >
                        {
                            props.api.stateListMED.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select City</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="CitySelect"
                        value={city}
                        onChange={handleCityChange}
                        label="City"
                    >
                        {
                            props.api.cityListMED.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Area</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="AreaSelect"
                        value={area}
                        onChange={handleAreaChange}
                        label="Location"
                    >
                        {
                            props.api.areaListMED.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Location (Optional)</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="LocationSelect"
                        value={location}
                        onChange={handleLocationChange}
                        label="Location"
                    >
                        {
                            props.api.locationListMED.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>
                {/* <Typography className={classes.text} variant="p" gutterBottom>
                You can view Store List after selecting until Area as well</Typography>
            <br></br> */}
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Hospital</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="StoreSelect"
                        value={store}
                        onChange={handleStoreChange}
                        label="Store"
                    >
                        {
                            props.api.hospitalList.map((Name) => (
                                <MenuItem value={Name} key={Name}>
                                    {Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <br></br>
                <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    endIcon={<FavoriteIcon />}
                    onClick={handleAddFavorite}
                >
                    Add to Favorites!
                </Button>
                <br></br>
                <br></br>
                <FormControl component="fieldset" style={{marginRight: "4rem"}}>
                    <FormLabel component="legend" style={{textAlign: "left", }}>Slot for</FormLabel>
                    <RadioGroup aria-label="slot for" name="slotfor" value={depValue} onChange={handleDependencyChange}>
                        <FormControlLabel value="SELF" control={<ColoredRadio />} label="Self"/>
                        <FormControlLabel value="DEP" control={<ColoredRadio />} label="Dependent" />
                    </RadioGroup>
                </FormControl>
                <br></br>
                <br></br>
                <div style={depValue === "SELF" ? {display: "none"} : {}}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <TextField
                    variant="outlined" InputLabelProps={{
                        shrink: true,
                    }}
                        labelId="demo-simple-select-outlined-label1"
                        id="NameDep"
                        defaultValue={name}
                        onChange={handleNameChange}
                        label="Name of Dependent"
                    >
                    </TextField>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <TextField
                    variant="outlined" InputLabelProps={{
                        shrink: true,
                    }}
                        labelId="demo-simple-select-outlined-label1"
                        id="NameDep"
                        defaultValue={phNo}
                        onChange={handlePhNoChange}
                        label="Phone No. of Dependent"
                    >
                    </TextField>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl component="fieldset" style={{marginRight: "4rem"}}>
                    <FormLabel component="legend" style={{textAlign: "left", }}>Gender of Dependent</FormLabel>
                    <RadioGroup aria-label="slot for" name="slotfor" value={gender} onChange={handleGenderChange}>
                        <FormControlLabel value="MALE" control={<ColoredRadio />} label="Male"/>
                        <FormControlLabel value="FEMALE" control={<ColoredRadio />} label="Female" />
                        <FormControlLabel value="OTHER" control={<ColoredRadio />} label="Other" />
                    </RadioGroup>
                </FormControl>
                <br></br>
                <br></br>
                </div>
                <img src={down} className="App-Down" alt="logo" role="button" onClick={() => { window.scrollTo(0, document.body.scrollHeight) }} />
            </div>
        );
    }
}

MainChooser.propTypes = {
    auth: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    getStateList_MED: PropTypes.func.isRequired,
    setStateName_MED: PropTypes.func.isRequired,
    getCityList_MED: PropTypes.func.isRequired,
    setCityName_MED: PropTypes.func.isRequired,
    getAreaList_MED: PropTypes.func.isRequired,
    setAreaName_MED: PropTypes.func.isRequired,
    getLocationList_MED: PropTypes.func.isRequired,
    setLocationName_MED: PropTypes.func.isRequired,
    getHospitalList_MED: PropTypes.func.isRequired,
    setHospitalName_MED: PropTypes.func.isRequired,
    getTestsList_MED: PropTypes.func.isRequired,
    setStoreName: PropTypes.func.isRequired,
    getStoreDetails: PropTypes.func.isRequired,
    addFavorites_MED: PropTypes.func.isRequired,
    setDependency_MED: PropTypes.func.isRequired,
    setGender_MED: PropTypes.func.isRequired,
    setName_MED: PropTypes.func.isRequired,
    setPhNo_MED: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    api: state.api,
});

export default connect(mapStateToProps, {
    getStateList_MED,
    setStateName_MED,
    getCityList_MED,
    setCityName_MED,
    getAreaList_MED,
    setAreaName_MED,
    getLocationList_MED,
    setLocationName_MED,
    getHospitalList_MED,
    setHospitalName_MED,
    getTestsList_MED,
    setStoreName,
    getStoreDetails,
    addFavorites_MED,
    setDependency_MED,
    setGender_MED,
    setName_MED,
    setPhNo_MED,
})(MainChooser);
