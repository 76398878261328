import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';

import SlotDrawer from './slotDrawer';
import UnitTimings from './unitTimingsAdder';
import WorkerAdder from './unitWorkerAdder';

import {
    setAdderContent,
    addUnit,
} from './../../actions/APIActions';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 420,
        textAlign: 'left',
        margin: 'auto',
        marginBottom: "1rem",
        marginLeft: "1rem",
        marginRight: "1rem"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
    },
    textfield: {
        marginBottom: '1rem'
    },
    timings: {
        marginBottom: '1rem'
    }
}));

const UnitAdder = (props) => {
    const classes = useStyles();
    const [category, setCategory] = React.useState("");
    const [name, SetName] = React.useState("");
    const [desc, setDesc] = React.useState("");
    const [pic, setPic] = React.useState("");
    const [addr, setAddr] = React.useState("");
    const [slotSize, setSlotSize] = React.useState("");

    const [openMonday, setOpenMonday] = React.useState(false);
    const [openTuesday, setOpenTuesday] = React.useState(false);
    const [openWednesday, setOpenWednesday] = React.useState(false);
    const [openThursday, setOpenThursday] = React.useState(false);
    const [openFriday, setOpenFriday] = React.useState(false);
    const [openSaturday, setOpenSaturday] = React.useState(false);
    const [openSunday, setOpenSunday] = React.useState(false);

    const [timingsMonday, setTimingsMonday] = React.useState([]);
    const [timingsTuesday, setTimingsTuesday] = React.useState([]);
    const [timingsWednesday, setTimingsWednesday] = React.useState([]);
    const [timingsThursday, setTimingsThursday] = React.useState([]);
    const [timingsFriday, setTimingsFriday] = React.useState([]);
    const [timingsSaturday, setTimingsSaturday] = React.useState([]);
    const [timingsSunday, setTimingsSunday] = React.useState([]);

    const handleToggleTimingsMonday = (value) => () => {
        const currentIndex = timingsMonday.indexOf(value);
        const newChecked = [...timingsMonday];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTimingsMonday(newChecked);
        console.log(newChecked);
    };

    const handleToggleTimingsTuesday = (value) => () => {
        const currentIndex = timingsTuesday.indexOf(value);
        const newChecked = [...timingsTuesday];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTimingsTuesday(newChecked);
        console.log(newChecked);
    };

    const handleToggleTimingsWednesday = (value) => () => {
        const currentIndex = timingsWednesday.indexOf(value);
        const newChecked = [...timingsWednesday];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTimingsWednesday(newChecked);
        console.log(newChecked);
    };

    const handleToggleTimingsThursday = (value) => () => {
        const currentIndex = timingsThursday.indexOf(value);
        const newChecked = [...timingsThursday];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTimingsThursday(newChecked);
        console.log(newChecked);
    };

    const handleToggleTimingsFriday = (value) => () => {
        const currentIndex = timingsFriday.indexOf(value);
        const newChecked = [...timingsFriday];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTimingsFriday(newChecked);
        console.log(newChecked);
    };

    const handleToggleTimingsSaturday = (value) => () => {
        const currentIndex = timingsSaturday.indexOf(value);
        const newChecked = [...timingsSaturday];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTimingsSaturday(newChecked);
        console.log(newChecked);
    };

    const handleToggleTimingsSunday = (value) => () => {
        const currentIndex = timingsSunday.indexOf(value);
        const newChecked = [...timingsSunday];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTimingsSunday(newChecked);
        console.log(newChecked);
    };

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const update = () => {
        props.addUnit(
            props.api.selectedHospitalDetails._id,
            props.api.selectedHospitalDetails.stateName,
            props.api.selectedHospitalDetails.cityName,
            props.api.selectedHospitalDetails.areaName,
            props.api.selectedHospitalDetails.locationName,
            props.api.selectedHospitalDetails.hospitalName,
            name,
            category,
            {
                openCheck: true,
                displayPicture: pic,
                displayDescription: desc,
                address: addr,
                workerIds: props.api.workers,
                slotSize: slotSize,
                timings: props.api.timings,
            }
        )
    }

    return (
        <React.Fragment >
            {/* <Typography className={classes.text} variant="h5" align="center" style={{ marginTop: "1rem", marginBottom: "1rem" }}>Your Appointment</Typography> */}

            <Card className={classes.root}
            // variant={"outlined"}
            >
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {props.hospitalName[0]}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={props.hospitalName}
                />
                <CardMedia
                    className={classes.media}
                    image={pic}
                    title={props.hospitalUnitName}
                />
                <CardContent>
                </CardContent>
                <CardActions disableSpacing>
                    {/* <IconButton aria-label="add to favorites">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareIcon />
                            </IconButton> */}
                    {/* <Typography align="center" style={{ paddingLeft: "1rem" }}>
                        Details
                            </Typography>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton> */}
                </CardActions>
                <CardContent>
                    <TextField id="hosCat"
                        className={classes.textfield}
                        multiline
                        variant="outlined" label="Unit Category"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={props.displayDescription}
                        onChange={
                            (event) => {
                                console.log("Category Changed " + event.target.value)
                                setCategory(event.target.value)
                                let content = props.api.adderContent;
                                content.hospitalUnitCategoryName = event.target.value;
                                props.setAdderContent(content);
                            }
                        }
                    // InputProps={{
                    //     endAdornment: <InputAdornment position="end">
                    //         <IconButton
                    //             aria-label="Save"
                    //             onClick={update}
                    //             edge="end"
                    //         >
                    //             <SaveIcon />
                    //         </IconButton>
                    //     </InputAdornment>,
                    // }}
                    >
                    </TextField>
                    <TextField id="hosName"
                        className={classes.textfield}
                        multiline
                        variant="outlined" label="Unit Name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={props.displayDescription}
                        onChange={
                            (event) => {
                                console.log("Unit Name Changed " + event.target.value)
                                SetName(event.target.value)
                                let content = props.api.adderContent;
                                content.hospitalUnitName = event.target.value;
                                props.setAdderContent(content);
                            }
                        }
                    // InputProps={{
                    //     endAdornment: <InputAdornment position="end">
                    //         <IconButton
                    //             aria-label="Save"
                    //             onClick={update}
                    //             edge="end"
                    //         >
                    //             <SaveIcon />
                    //         </IconButton>
                    //     </InputAdornment>,
                    // }}
                    >
                    </TextField>
                    <TextField id="hosdesc"
                        className={classes.textfield}
                        multiline
                        variant="outlined" label="Hospital Description"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={props.displayDescription}
                        onChange={
                            (event) => {
                                console.log("Desc Changed " + event.target.value)
                                setDesc(event.target.value)
                                let content = props.api.adderContent;
                                content.displayDescription = event.target.value;
                                props.setAdderContent(content);
                            }
                        }
                    // InputProps={{
                    //     endAdornment: <InputAdornment position="end">
                    //         <IconButton
                    //             aria-label="Save"
                    //             onClick={update}
                    //             edge="end"
                    //         >
                    //             <SaveIcon />
                    //         </IconButton>
                    //     </InputAdornment>,
                    // }}
                    >
                    </TextField>
                    <TextField id="hospic"
                        className={classes.textfield}
                        multiline
                        variant="outlined" label="Hospital Picture URL"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={pic}
                        onChange={
                            (event) => {
                                console.log("DP Changed " + event.target.value)
                                setPic(event.target.value)
                                let content = props.api.adderContent;
                                content.displayPicture = event.target.value;
                                props.setAdderContent(content);
                            }
                        }
                    // InputProps={{
                    //     endAdornment: <InputAdornment position="end">
                    //         <IconButton
                    //             aria-label="Save"
                    //             onClick={update}
                    //             edge="end"
                    //         >
                    //             <SaveIcon />
                    //         </IconButton>
                    //     </InputAdornment>,
                    // }}
                    >
                    </TextField>
                    <TextField id="hosadd"
                        className={classes.textfield}
                        multiline
                        variant="outlined" label="Hospital Address"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={props.address}
                        onChange={
                            (event) => {
                                console.log("Addr Changed " + event.target.value)
                                setAddr(event.target.value)
                                let content = props.api.adderContent;
                                content.address = event.target.value;
                                props.setAdderContent(content);
                            }
                        }
                    // InputProps={{
                    //     endAdornment: <InputAdornment position="end">
                    //         <IconButton
                    //             aria-label="Save"
                    //             onClick={update}
                    //             edge="end"
                    //         >
                    //             <SaveIcon />
                    //         </IconButton>
                    //     </InputAdornment>,
                    // }}
                    >
                    </TextField>
                    <TextField id="hossize"
                        className={classes.textfield}
                        variant="outlined" label="Slot Size"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={props.slotSize}
                        onChange={
                            (event) => {
                                console.log("Slot Size Changed " + event.target.value)
                                setSlotSize(event.target.value)
                                let content = props.api.adderContent;
                                content.slotSize = event.target.value;
                                props.setAdderContent(content);
                            }
                        }
                    // InputProps={{
                    //     endAdornment: <InputAdornment position="end">
                    //         <IconButton
                    //             aria-label="Save"
                    //             onClick={update}
                    //             edge="end"
                    //         >
                    //             <SaveIcon />
                    //         </IconButton>
                    //     </InputAdornment>,
                    // }}
                    >
                    </TextField>
                    <Divider variant="middle" />
                    <Typography variant="h5"
                        style={{ marginTop: '1rem' }}
                        gutterBottom>
                        Edit Timings
                        </Typography>
                    <UnitTimings
                        className={classes.timings}
                        timings={[]}
                        weekday="Monday"
                        openCheck={false}
                    ></UnitTimings>
                    <UnitTimings
                        className={classes.timings}
                        timings={[]}
                        weekday="Tuesday"
                        openCheck={false}
                    ></UnitTimings>
                    <UnitTimings
                        className={classes.timings}
                        timings={[]}
                        weekday="Wednesday"
                        openCheck={false}
                    ></UnitTimings>
                    <UnitTimings
                        className={classes.timings}
                        timings={[]}
                        weekday="Thursday"
                        openCheck={false}
                    ></UnitTimings>
                    <UnitTimings
                        className={classes.timings}
                        timings={[]}
                        weekday="Friday"
                        openCheck={false}
                    ></UnitTimings>
                    <UnitTimings
                        className={classes.timings}
                        timings={[]}
                        weekday="Saturday"
                        openCheck={false}
                    ></UnitTimings>
                    <UnitTimings
                        className={classes.timings}
                        timings={[]}
                        weekday="Sunday"
                        openCheck={false}
                    ></UnitTimings>
                    <Divider
                        style={{ marginTop: '1rem' }}
                        variant="middle" />
                    <Typography variant="h5"
                        style={{ marginTop: '1rem' }}
                        gutterBottom>
                        Edit Authorized Personnel
                        </Typography>
                    <WorkerAdder
                        className={classes.timings}
                        workerIds={[]}
                        weekday="Friday"></WorkerAdder>
                </CardContent>
                <CardActions>
                    <Button size="small" color="primary" variant="outlined"
                        onClick={update}
                    >
                        Save and Add Unit
                    </Button>
                </CardActions>
            </Card>
        </React.Fragment>
    )
}

UnitAdder.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    setAdderContent: PropTypes.func.isRequired,
    addUnit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    api: state.api,
    auth: state.auth,
})


export default connect(mapStateToProps, {
    setAdderContent,
    addUnit,
})(UnitAdder)
