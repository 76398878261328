import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';

import SlotDisplay from './slotDisplay';

import {
    getSlotListofUnit
} from './../../actions/APIActions';
let i = 0;
let drawerHeight = 240;
const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    card: {
        maxWidth: 345,
        margin: theme.spacing(2),
    },
    media: {
        height: 190,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const SlotDrawer = (props) => {
    const classes = useStyles();
    const { loading = true } = props;
    const [anchorDir, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);

        props.getSlotListofUnit(
            props.hospitalId,
            props.hospitalName,
            props.hospitalUnitName,
            props.hospitalUnitCategoryName,
        )
    };

    const handleClose = () => {
        setOpen(false);
    };

    console.log(
        "Own",
        props.own,
        props.api.selectedHospitalUnit,
        props.api,
        props.auth,
        props.hospitalId,
        // props.hospitalId,
        // props.hospitalName,
        // props.hospitalUnitName,
        // props.hospitalUnitCategoryName,

        // props.hospitalId,
        // props.hospitalName,
        // props.hospitalUnitName,
        // props.hospitalUnitCategoryName,
    )
    // if (props.api.selectedHospitalUnit === "" && i == 0) {

    console.log(
        // props.hospitalId,
        // props.hospitalName,
        // props.hospitalUnitName,
        // props.hospitalUnitCategoryName,
        props.hospitalId,
        props.hospitalName,
        props.hospitalUnitName,
        props.hospitalUnitCategoryName
    );

    //}

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        props.getSlotListofUnit(
            props.hospitalId,
            props.hospitalName,
            props.hospitalUnitName,
            props.hospitalUnitCategoryName,
        )

        setState({ ...anchorDir, [anchor]: open });
    };

    const anchor = 'bottom';

    return (
        <React.Fragment key={anchor}>
            <div>
                <Button variant="outlined" size="small" color="primary" onClick={handleClickOpen}>
                    {props.text}
                </Button>
                <Dialog 
                
                fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {props.hospitalUnitCategoryName + " ▶ " + props.hospitalUnitName}
                            </Typography>
                            {/* <Button autoFocus color="inherit" onClick={handleClose}>
                                save
            </Button> */}
                        </Toolbar>
                    </AppBar>
                    <List style={{backgroundColor: '#303030'}}>
                            {props.api.selectedHospitalUnit === ""
                                ?
                                (
                                    <PlaceHolderCard></PlaceHolderCard>
                                )
                                :
                                props.api.selectedHospitalUnit.map((
                                    {
                                        slot,
                                        hospitalId,
                                        hospitalName,
                                        address,
                                        displayDescription,
                                        displayPicture,
                                        timings,
                                        slotSize,
                                        openCheck,
                                    }) => (
                                        <SlotDisplay
                                            hospitalName={hospitalName}
                                            slot={slot}
                                            address={address}
                                            displayDescription={displayDescription}
                                            displayPicture={displayPicture}
                                        ></SlotDisplay>
                                        // <h1>HI!</h1>
                                    ))
                            }
                    </List>
                </Dialog>
            </div>
        </React.Fragment >
    )
}

SlotDrawer.propTypes = {
    //props: PropTypes,
    auth: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    getSlotListofUnit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    api: state.api,
})

export default connect(mapStateToProps, {
    getSlotListofUnit,
})(SlotDrawer)

const PlaceHolderCard = () => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={(
                    <Skeleton animation="wave" variant="circle" width={40} height={40} />
                )
                }

                title={
                    (
                        <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                    )
                }
                subheader={<Skeleton animation="wave" height={10} width="40%" />}
            />
            {(
                <Skeleton animation="wave" variant="rect" className={classes.media} />
            )}

            <CardContent>
                {(
                    <React.Fragment>
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    )
}