import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withFirebaseAuth from 'react-with-firebase-auth'
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';

import { connect } from 'react-redux';
import { saveAuthInfo } from './actions/AuthActions';
import './App.css';
import Button from '@material-ui/core/Button';
//import Home from './containers/home';
//import Dashboard from './containers/Dashboard';
//import BottomNavBar from './components/BottomNavBar';
//import ChatUI from './containers/ChatUI';
//import ChatList from './containers/ChatList';
//import Login from './containers/login';
//import MenuAppBar from './components/PrimaryAppBar';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import history from './store';
import queryString from "query-string";
import Cookies from 'js-cookie';
import { withTheme } from '@material-ui/core/styles';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import blue from '@material-ui/core/colors/blue';
import ReactPlayer from 'react-player'

// import {
//   socket
// } from './sockets/index'
//import { withCookies, Cookies } from 'react-cookie';
import {
  SAVEAUTHINFO, SETUNBANTIME, SETRESERVELOCK
} from './actions/types';
import logo from './logo.svg';
import './App.css';
import MainChooser from './components/customer/MainChooser';
import SlotsDisplay from './components/customer/SlotsDisplay';
import TicketDisplay from './components/customer/TicketDisplay';
import PrimaryAppBar from './components/PrimaryAppBar';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import LockOpenIcon from '@material-ui/icons/LockOpen';
//import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Paper from '@material-ui/core/Paper';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CustomerPage from './pages/customer';
import PageRouter from './pages/ShoppingPageRouter';
import TabRouter from './pages/tabPage';
import axios from 'axios';
var moment = require('moment');

const useStyles = makeStyles({
  root: {
    top: 'auto',
    bottom: 0,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  div: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    margin: 'auto'
  }
});
require('dotenv').config();

const darktheme = createMuiTheme({
  palette: {
    primary: blue,
    type: 'dark',
    secondary: {
      main: '#424242'
    }
  },
});

const lighttheme = createMuiTheme({
  palette: {
    primary: blue,
    type: 'light',
    secondary: {
      main: '#ffffff'
    }
  },
});


function App(props) {
  // const {
  //   user,
  //   signOut,
  //   signInWithGoogle,
  // } = this.props;


  // //const [userData, setUserData] = useState(Cookies.getJSON('UserAuthData'));

  // useEffect(() => {
  //   console.log("UserData: ", userData)
  //   if (userData !== undefined){
  //     console.log("Theme Saved: ", userData.settings.darkmode)
  //     if(userData.settings.darkmode === true){
  //       props.darkmode(true, "", false)
  //     }
  //   }
  //   console.log(i++);
  // }, [userData]);

  // useEffect(() => {
  //   console.log("Theme: ", props.braggi.darkmode)

  //   if(props.braggi.darkmode === true) {
  //     document.body.style.background = "linear-gradient(45deg, #212121 30%, #212121 90%)";
  //   }
  //   else {
  //     document.body.style.background = "linear-gradient(45deg, #CFD8DC 30%, #ECEFF1 90%)";
  //   }


  //   document.body.style.backgroundRepeat = "no-repeat";
  //   document.body.style.backgroundAttachment = "fixed";
  // }, [props.braggi.darkmode]);
  //console.log(Cookies.getJSON('UserAuthData'));


  const classes = useStyles();
  const {
    user,
    signOut,
    signInWithGoogle,
  } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  if (user) {
    //if (user !== undefined) {
    console.log("Moment Exp");
    var a = moment("11PM", "HHa")
    console.log(a)
    var b = moment()
    console.log(b)
    console.log(a.isSameOrAfter(b))
    console.log(moment("5AM", "HHa").isSameOrAfter(moment()))
    //setCookie("UserAuthData", user)
    Cookies.set("UserAuthData", { email: user.email, displayName: user.displayName })
    var x = Cookies.getJSON('UserAuthData')
    store.dispatch({
      type: SAVEAUTHINFO,
      payload: { email: user.email, displayName: user.displayName }
    })
    axios.post(process.env.REACT_APP_API_URL + 'AUTH/userAuth', x).then(res => {
      //console.log("Result", res)
      store.dispatch({
        type: SAVEAUTHINFO,
        payload: {
          ...res.data.userAuthData,
          JWT: res.data.token,
        }
      })
      if (res.data.userAuthData.unbanTime) {
        store.dispatch({
          type: SETUNBANTIME,
          payload: res.data.userAuthData.unbanTime
        })
        store.dispatch({
          type: SETRESERVELOCK,
          payload: res.data.userAuthData.banned
        })
      }

    })
    //console.log(x)
    console.log("authenticating")
    //socket.emit('userAuth', x)
    //console.log("State: ", store.getState().auth)
    return (
      <ThemeProvider theme={darktheme}><CssBaseline />
        {/* <Provider store={store}> */}
        <Router history={history}>
          {/* <div className="App">
            <header className="App-header">
              <Typography className={classes.text} variant="h1">
                Braggi
              </Typography>
              <Typography className={classes.text} variant="h6">
                Shop Safely during Lockdowns
              </Typography>
              <img src={logo} className="App-logo" alt="logo"/>
              <MainChooser></MainChooser>
            </header>
            <SlotsDisplay></SlotsDisplay>
            <TicketDisplay></TicketDisplay>
          </div> */}
            {/* <PageRouter />         */}
            <TabRouter></TabRouter>
          {/* <div>
            <PageRouter />
            <BottomNavigation
              value=""
              onChange={(event, newValue) => {
                
              }}
              showLabels
              className={classes.root}
            >
              <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
              <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
              <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
            </BottomNavigation>
          </div> */}
          {/* <Route path="/" exact component={ChatList} />
              <Route path="/settings/" component={Dashboard} />
              <Route path="/chat/" component={ChatUI} /> */}
        </Router>
        {/* </Provider> */}
      </ThemeProvider>
    );
  }
  else {
    return (
      <ThemeProvider theme={darktheme}><CssBaseline />
        {/* <Provider store={store}> */}
        <Router history={history}>
          <div className={classes.div}>
            {/* <div>
            <MenuAppBar></MenuAppBar>
        </div> */}
            {/* <button onClick={signInWithGoogle}>Sign in with Google</button> */}

            {/* <Route path="/" exact component={Home} />
            <Route path="/about/" component={About} />
            <Route path="/chat/" component={ChatUI} /> */}
            <PrimaryAppBar></PrimaryAppBar>
            <header className="App-header">
              <Typography className={classes.text} variant="h1">
                Braggi
              </Typography>
              <Typography className={classes.text} variant="h6">
                Shop Safely during Lockdowns
              </Typography>
              <img src={logo} className="App-logo" alt="logo" />
              <Card className={classes.root}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Store Slot Booking
          </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    With this App you can book a slot at your local grocery store/super market and safely visit when the crowd is controlled and managed.
          </Typography>
                  <br></br>

                  <Typography variant="body2" color="textSecondary" component="p">
                    <em>Sign ins maybe slower on Mobile Data (This is only for the first sign in)</em>        </Typography>
                </CardContent>
                <CardActions>
                  <Button size="large" variant="contained" color="primary" endIcon={<LockOpenIcon></LockOpenIcon>} onClick={() => { signInWithGoogle() }}>Sign in with Google
                </Button>
                </CardActions>
                {/* <Alert variant="outlined" severity="info">
        This is an info alert — check it out!
      </Alert> */}
              </Card>
              <br></br>
              <Card className={classes.root}>
                <CardActionArea>

                  {/* <CardMedia
                  component="img"
                  alt="App logo"
                  width="110"
                  image={logo}
                  title="App logo"
                /> */}
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Learn More
                    </Typography>
                    <br></br>
                    <ReactPlayer width="140" url='https://youtu.be/A7zMSeS6UFQ' playing={false} controls />
                    <br></br>
                    <Typography variant="body2" color="textSecondary" component="p">
                      With this App you can book a slot at your local grocery store/super market and safely visit when the crowd is controlled and managed.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" variant="contained" color="primary" endIcon={<LockOpenIcon></LockOpenIcon>} onClick={() => { signInWithGoogle() }}>Interested ? Sign in with Google
                  </Button>
                </CardActions>
              </Card>
            </header>
          </div>
        </Router>
        {/* </Provider> */}
      </ThemeProvider>
    )
  }
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);