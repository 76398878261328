import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Icon from '@material-ui/core/Icon';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'react-redux-firebase';
import {
    api_reserveSlot,
    updateVisited
} from '../../actions/APIActions';
import { SLOTTIMES } from './../../settings/slots';
import Checkbox from '@material-ui/core/Checkbox';
var moment = require('moment');

var slotTimes = SLOTTIMES

const open = true;
const messages = [
    {
        time: "10AM",
        slotSize: 25,
        slotsAvailable: 6,
        person: '/static/images/avatar/5.jpg',
    },
    {
        time: "11AM",
        slotSize: 25,
        slotsAvailable: 5,
        person: '/static/images/avatar/1.jpg',
    },
    {
        time: "12PM",
        slotSize: 25,
        slotsAvailable: 14,
        person: '/static/images/avatar/2.jpg',
    },
    {
        time: "3PM",
        slotSize: 25,
        slotsAvailable: 12,
        person: '/static/images/avatar/3.jpg',
    },
    {
        time: "4PM",
        slotSize: 25,
        slotsAvailable: 15,
        person: '/static/images/avatar/4.jpg',
    },
    {
        time: "5PM",
        slotSize: 25,
        slotsAvailable: 1,
        person: '/static/images/avatar/5.jpg',
    },
    {
        time: "6PM",
        slotSize: 25,
        slotsAvailable: 25,
        person: '/static/images/avatar/1.jpg',
    },
];

const useStyles = makeStyles(theme => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function SlotsDisplay(props) {
    const classes = useStyles();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [selectedSlot, setSelectedSlot] = React.useState('');

    const handleVisitedToggle = (number) => event => {
        console.log("recieved: ", event.target.checked)
        document.getElementById(number + 'check').checked = event.target.checked;
        props.updateVisited(props.api.selectedStoreDetails._id, number, event.target.checked, selectedSlot)
    }

    const toggleDrawer = (anchor, open, details, time) => event => {
        if (open === true) {
            setSelectedSlot(time)
            showAttendees(details, time)
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const showAttendees = (details, time) => {
        //console.log("details ", details, "time ", time)
        console.log(details.slots["slot" + time].slotsReservedby)
        console.log(details.slots["slot" + time].slotsAttendedby);
    }

    if (isEmpty(props.api.selectedStoreDetails)) {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }

    // else if (props.auth.unbanTime !== "" && props.auth.reserveLock !== false) {
    //     return (
    //         <Typography className={classes.text} variant="h4" gutterBottom style={{ marginBottom: '7rem' }}>
    //             {
    //                 props.auth.unbanTime !== "" && props.auth.reserveLock !== false
    //                     ?
    //                     "Can't book anymore slots today."
    //                     : ""
    //             }
    //         </Typography>
    //     )
    // }

    else {
        return (
            <React.Fragment>
                <CssBaseline />
                <Paper square className={classes.paper} >
                    <Typography className={classes.text} variant="h6" gutterBottom>
                        {"Status: "}{props.api.selectedStoreDetails.openCheck === true ? <b>Open</b> : <b>Closed</b>}
                    </Typography>
                    {
                        props.api.selectedStoreDetails.openCheck !== undefined
                            ?
                            (
                                <List className={classes.list} >
                                    {/* {SlotItem5AM((props))} */}
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("5AM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot5AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot5AM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + (parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot5AM.slotsAvailable))} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "5AM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("6AM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot6AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot6AM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot6AM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "6AM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("7AM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot7AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot7AM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot7AM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "7AM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("8AM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot8AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot8AM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot8AM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "8AM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("9AM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot9AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot9AM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot9AM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "9AM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("10AM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot10AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot10AM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot10AM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "10AM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("11AM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot11AM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot11AM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot11AM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "11AM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("12PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot12PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot12PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot12PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "12PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("1PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot1PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot1PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot1PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "1PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("2PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot2PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot2PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot2PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "2PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("3PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot3PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot3PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot3PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "3PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("4PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>AM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot4PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot4PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot4PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "4PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("5PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot5PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot5PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot5PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "5PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("6PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot6PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot6PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot6PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "6PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("7PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot7PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot7PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot7PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "7PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("8PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot8PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot8PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot8PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "8PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("9PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot9PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot9PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot9PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "9PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("10PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot10PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot10PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot10PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "10PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                    {
                                        props.api.selectedStoreDetails.slotTimings.includes("11PM")
                                            ? (
                                                <React.Fragment>
                                                    <ListItem button>
                                                        <ListItemAvatar>
                                                            {/* <Avatar src={person}>
                    <p>{time}<sub>PM</sub></p>
                    </Avatar> */}
                                                            <Chip
                                                                variant="outlined"
                                                                size="medium"
                                                                avatar={<Avatar>{String(props.api.selectedStoreDetails.slots.slot11PM.time).replace(/(AM|PM)/g, "")}</Avatar>}
                                                                label={String(props.api.selectedStoreDetails.slots.slot11PM.time).replace(/[0-9]/g, '')}
                                                                color="primary"

                                                                deleteIcon={<DoneIcon />}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={"Capacity:" + props.api.selectedStoreDetails.slotSize} secondary={"Available:" + parseInt(props.api.selectedStoreDetails.slotSize - props.api.selectedStoreDetails.slots.slot11PM.slotsAvailable)} />
                                                        <ListItemSecondaryAction>
                                                            <Button onClick={toggleDrawer("bottom", true, props.api.selectedStoreDetails, "11PM")} variant="outlined" color="primary" className={classes.button} endIcon={<OpenInNewIcon />}>View</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                            : (<React.Fragment></React.Fragment>)
                                    }
                                </List>
                            )
                            :
                            (
                                <div></div>
                            )
                    }
                    <React.Fragment>
                        <SwipeableDrawer 
                            anchor={"bottom"}
                            open={state["bottom"]}
                            onClose={toggleDrawer("bottom", false)}
                            onOpen={toggleDrawer("bottom", true)}
                        >
                            <Card className={classes.root} style={{overflow:'scroll'}}>
                                <CardActionArea>
                                    <List dense className={classes.root} style={{ marginBottom: '1.5rem' }}>
                                        <ListItem key={0}>
                                            <ListItemText>
                                                <Typography className={classes.text} variant="h4" >
                                                    {selectedSlot}
                                                </Typography>
                                                <Typography className={classes.text} variant="h6">
                                                    {props.api.selectedStoreDetails.slots["slot" + selectedSlot] !== undefined ?
                                                        props.api.selectedStoreDetails.slots["slot" + selectedSlot].slotsAvailable + " / " + props.api.selectedStoreDetails.slotSize + " Slots Filled"
                                                        : ""}
                                                </Typography>
                                                <Typography className={classes.text} variant="h6" gutterBottom>
                                                    {props.api.selectedStoreDetails.slots["slot" + selectedSlot] !== undefined ?
                                                        props.api.selectedStoreDetails.slots["slot" + selectedSlot].slotsReservedby.filter(value => value.visited === true).length + " / " + props.api.selectedStoreDetails.slots["slot" + selectedSlot].slotsReservedby.length + " Visited"
                                                        : ""}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <Divider style={{ marginBottom: '1.5rem' }} />
                                        {props.api.selectedStoreDetails.slots["slot" + selectedSlot] !== undefined
                                            ?
                                            props.api.selectedStoreDetails.slots["slot" + selectedSlot].slotsReservedby.map(({ _id, number, visited }) => (
                                                <React.Fragment>
                                                    <ListItem key={number} button>
                                                        <ListItemAvatar>
                                                            <Avatar
                                                            >{number}</Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText id={number + "text"} primary={"Name Hidden"} />
                                                        <ListItemSecondaryAction id={number + "action"}>
                                                            <Checkbox
                                                                id={number + 'check'}
                                                                edge="end"
                                                                onClick={handleVisitedToggle(number)}
                                                                defaultChecked={visited}
                                                                color="default"
                                                                inputProps={{ 'aria-label': 'Checkbox A' }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                </React.Fragment>
                                            ))
                                            :
                                            "undefined"}
                                    </List>
                                </CardActionArea>
                            </Card></SwipeableDrawer>
                    </React.Fragment>
                </Paper>
            </React.Fragment>
        );
    }
}


SlotsDisplay.propTypes = {
    api: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    updateVisited: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    updateVisited
})(SlotsDisplay);